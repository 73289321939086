import React, { useEffect, useState } from 'react'
import { useCreateInvNatureMutation, useGetInvnatureQuery, useUpdateInvNatureMutation } from '../inventoryConfiguratorsService';
import useTableFilters from '../../../hooks/useTableFilter';
import AddInvoiceNature from './AddInvoiceNature';
import { Button, Modal, Table, Tag, Tooltip } from 'antd';
import { EditFilled, PlusOutlined } from '@ant-design/icons';
import UpdateInvoiceNature from './UpdateInvoiceNature';

const InvoiceNature = () => {
    const { data, isLoading,refetch} = useGetInvnatureQuery();
    const [createinvoicenature, { isSuccess: addSuccess, isLoading: addLoading }] = useCreateInvNatureMutation();
    const [ updatenature, {data:updatenatureData, isSuccess: updateSuccess, isLoading: updateLoading }, ] = useUpdateInvNatureMutation();
    const [showAddModal, setShowAddModal] = useState(false);
    const [rowData, setRowData] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const filter = useTableFilters();
    const[tableDatainfo,setTableDatainfo]=useState([]);
    useEffect(()=>{
        if(data){
          setTableDatainfo(data?.data)
        }
      },[data])
      useEffect(() => {
        if (addSuccess) setShowAddModal(false);
         handlerefetch()
      }, [addSuccess]); // eslint-disable-line
  
      useEffect(() => {
        if (updateSuccess) setShowUpdateModal(false);
        handlerefetch();
      }, [updateSuccess]); // eslint-disable-line
    const tableColumns = [
        {
            title:"Nature ID",
            dataIndex: "natid",
            ...filter("natid"),
         
          },
        {
          title:"Nature",
          dataIndex: "natname",
          ...filter("natname"),
       
        },
  
    
          {
            title: "Status",
            render: (_, { status }) =>
              status === "Y" ? (
                <Tag color="green">Active</Tag>
              ) : (
                <Tag color="red">In Active</Tag>
              ),
            sorter: (a, b) => a?.status?.localeCompare(b?.status),
          },
          {
            title: "Action(s)",
            render: (data) => (
              <Tooltip title="Update Nature">
                <Button
                  type="primary"
                  className="btn-warning-primary"
                  icon={<EditFilled />}
                  onClick={() => {
                    setRowData(data);
                    setShowUpdateModal(true);
                  }}
                />
              </Tooltip>
            ),
          },
       
       
      ];
      const handlerefetch=()=>{
        refetch()
      }
    return (
      <section>
      <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">Nature</h1>
        <Tooltip  title="Add New Nature ">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className='p-2'>
        <Table
         columns={tableColumns}
         dataSource={tableDatainfo}
          pagination={{ defaultPageSize: 5 }}
          loading={isLoading}
          bordered
          size='small'
          scroll={{ x: "fit-content" }}
          rowKey='type'
   
          
        />
      </div>
      <Modal
      title="Add Nature"
      open={showAddModal}
      onCancel={() => setShowAddModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="addnatureForm"
          loading={addLoading}
          type="primary"
        >
          Add
        </Button>,
      
      ]}
    >
  <AddInvoiceNature
    createinvoicenature={createinvoicenature} 
    handlerefetch={handlerefetch}
    />
    </Modal>
  
    <Modal
        title="Update Nature"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updatenatureForm"
          loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateInvoiceNature
          rowData={rowData}
          updatenatureData={updatenatureData}
          updatenature={updatenature}
        />
      </Modal>
  
    </section>
    )
  }
export default InvoiceNature