import { DatePicker, Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import moment from 'moment'; // Import moment
import formValidationMessages from '../../../utils/formValidationMessages';
import { useLovInvUomQuery, useLovItemListQuery, useLovPurchaseOrderQuery } from '../inventorypurchaseService';

const AddInvPurchaseDetail = ({ createpodetail }) => {
  const [form] = Form.useForm(); // Initialize form instance
  const { data: lovPO } = useLovPurchaseOrderQuery(); // Fetch Purchase Order data
  const { data: lovItemList } = useLovItemListQuery();
  const { data: lovUOM } = useLovInvUomQuery();

  // Handle when the user selects a Purchase Order
  const handlePurchaseOrderSelect = (ponum) => {
    const selectedPO = lovPO?.data?.find((item) => item.ponum === ponum);
    if (selectedPO) {
      // Set the form values when a PO is selected
      form.setFieldsValue({
        purchaseseqnum: selectedPO.purchaseseqno, // Set Purchase Seq No
        purchasenum: selectedPO.ponum, // Set Purchase Number
        purchasedate: selectedPO.podate ? moment(selectedPO.podate) : null, // Set Purchase Date
      });
    }
  };

  const onFinish = (values) => {
    const formattedDate = values.purchasedate ? moment(values.purchasedate).format('YYYY-MM-DD') : null;

    createpodetail({
      ...values,
      purchasedate: formattedDate, 
      message: 'B',
      
    });
  };

  return (
    <div>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        
        id='addpodetailForm'
        validateMessages={formValidationMessages}
        className='grid grid-cols-5 gap-2'
      >
        {/* First dropdown to select Purchase Order */}
        <Form.Item
          name='purchaseseqnum'
          label='Select Purchase Order'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder='Select Purchase Order'
            options={lovPO?.data?.map((item) => ({
              value: item?.ponum,
              label: `PO Number: ${item?.ponum}`,
            }))}
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
            onChange={handlePurchaseOrderSelect} // Trigger this when PO is selected
          />
        </Form.Item>

        {/* Purchase Seq No (automatically set based on selected Purchase Order) */}
        <Form.Item
          name='purchaseseqnum'
          label='Purchase Seq No.'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder='Enter Purchase SeqNo.' disabled />
        </Form.Item>

        {/* Purchase No (automatically set based on selected Purchase Order) */}
        <Form.Item
          name='purchasenum'
          label='Purchase No.'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder='Enter Purchase No.' disabled />
        </Form.Item>

        {/* Purchase Date (automatically set based on selected Purchase Order) */}
        <Form.Item
          name='purchasedate'
          label='Purchase Date'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker placeholder='Enter Purchase Date' format='MM/DD/YYYY' disabled />
        </Form.Item>

        {/* Additional form fields for description, item selection, etc. */}
        <Form.Item
          name='descr'
          label='Description'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder='Enter Description' />
        </Form.Item>

        <Form.Item
          name='itemid'
          label='Item Name'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder='Select Item Name'
            options={lovItemList?.data?.map((item) => ({
              value: item?.code,
              label: `${item?.descr}`,
            }))}
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
          />
        </Form.Item>
   
    <Form.Item
      name='quantity'
      label='Quantity'
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter Quantity'/>
    </Form.Item>
    <Form.Item
      name='rate'
      label='Rate'
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter Rate'/>
    </Form.Item>
    <Form.Item
      name='unitrate'
      label='Unit Rate'
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter Unit Rate'/>
    </Form.Item>
  
    <Form.Item
              name='uomid'
              label="UOM"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Select UOM"
                options={lovUOM?.data?.map((item) => ({
                  value: item?.code,
                  label: `${item?.descr}`,
                }))}
                showSearch
                filterOption={(input, option) =>
                  option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                }
              />
            </Form.Item>
   
    <Form.Item
      name='lastrcvqty'
      label='Last Recevied Quantity'
      rules={[
        {
          required: true,
        },
      ]}
 >
      <Input placeholder='Enter Last Recevied Quantity '/>
    </Form.Item>

    <Form.Item
      name='currrcvqty'
      label='Current Quantity'
      rules={[
        {
          required: true,
        },
      ]}
 >
      <Input placeholder='Enter Current Quantity'/>
    </Form.Item>

   
    <Form.Item
      name='taxpct'
      label='Tax Packet'
      rules={[
        {
          required: true,
        },
      ]}
 >
      <Input placeholder='Enter Tax Pac'/>
    </Form.Item>

    <Form.Item required
      name='discountpct' label='Discount Packet'
      rules={[
        {
          required: true,
        },
      ]}>
          <Input  placeholder='Enter Discount Packet' ></Input>
        
        </Form.Item>

        <Form.Item
      name='discountamount'
      label='Discount Amount'
      rules={[
        {
          required: true,
        },
      ]}
 >
      <Input placeholder='Enter Discount Amount'/>
    </Form.Item>

    <Form.Item
      name='packsize'
      label='Packet Size'
      rules={[
        {
          required: true,
        },
      ]}
 >
      <Input placeholder='Enter Packet Size'/>
    </Form.Item>


    <Form.Item
      name='extraqty'
      label='Extra Quantity'
      rules={[
        {
          required: true,
        },
      ]}
 >
      <Input placeholder='Enter Extra Quantity'/>
    </Form.Item>

    <Form.Item
      name='retailprice'
      label='Retail Price'
      rules={[
        {
          required: true,
        },
      ]}
 >
      <Input placeholder='Enter Retail Price'/>
    </Form.Item>

   
    
    <Form.Item name='status' label='Status'>
        <Select
          placeholder='Select  Status'
          options={[
            { label: 'Active', value: 'Y' },
            { label: 'In Active', value: 'N' },
          ]}
          
        />
      </Form.Item>
   
   
  </Form>
    </div>
  )
}



export default AddInvPurchaseDetail