/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker, Form, Input, Select} from "antd";
import React, { useEffect } from "react";
import {
  useGetunitlovQuery,
  useLovgetponomnlistMutation,
  useLovgetporeqtypeQuery,
  useLovgetposessionQuery,
  useLovgetvendorlistQuery,
  useSavepoMutation,
} from "../inventoryService";
import { useSelector } from "react-redux";
import formValidationMessages from "../../../utils/formValidationMessages";

const AddPurchaseOrder = ({ setPoDetail, onCancel }) => {
  const [form] = Form.useForm();

  const { data: getvendorlist } = useLovgetvendorlistQuery();
  const { data: getporeqtype } = useLovgetporeqtypeQuery();
  const { data: getposession } = useLovgetposessionQuery();
  const {data:getunit}=useGetunitlovQuery('co');

  const [savevendor] = useLovgetponomnlistMutation();
  const [savepo, { data: AllPoDetailData }] = useSavepoMutation();
  useEffect(() => {
    if (AllPoDetailData) {
      setPoDetail(AllPoDetailData?.data);
    }
  }, [AllPoDetailData]);
  const co = useSelector((state) => state.auth.user.co);
  const userid = useSelector((state) => state.auth.user.empno);



  

  const onFinish = (values) => {
    // console.log("values", values);
    if(values)
    {
    savepo({
      ponum: values?.po,
      co: co,
      userid: userid,
      vendorid: values?.vendor,
      quotnum: values?.quotnum,
      unit: "MAINMEDSTR",
      reqtype: values?.purchase,
      podate: values?.podate,
      // presdate: formateDate(values?.presdate),
      sessionid: values?.sessionid,
      descr: values?.descr,
      quotdate: values?.quotationdate,
    
    });
    if (savepo){
      onCancel()
    }}
    
  };
  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      autoComplete='off'
      id='addPurchaseOrderForm'
      validateMessages={formValidationMessages}
    >
      <div className='grid sm:grid-cols-4 grid-cols-1 gap-2'>
        <Form.Item name='po' label='PO No.'
        rules={[
                  {
                    required: true,
                  },
                ]}
        >
          <Input placeholder='Enter PO No.' />
        </Form.Item>

        <Form.Item name='vendor' label='Vendor Name'>
          <Select
            placeholder='Select Vendor'
            onChange={(value) => {
              savevendor({
                //  co:co,
                unit: "MAINMEDSTR",
                vendorid: value,
              });
            }}
            options={getvendorlist?.data?.map((item) => ({
              value: item?.vendorid,
              label: item?.vendordescr,
            }))}
          />
        </Form.Item>
        <Form.Item name='unit' label='Unit'>
          <Select
            placeholder='Select Unit'
            options={getunit?.data?.map((item) => ({
              value: item?.orgunitid,
              label: item?.unitname
,
            }))}
          />
        </Form.Item>



        <Form.Item name='podate' label='PO Date'>
          <DatePicker
            // disabledDate={futureDates}
            placeholder='Enter PO Date'
            format='DD-MMM, YYYY'
          />
        </Form.Item>
        <Form.Item name='purchase' label='Purchase Type'>
          <Select
            placeholder='Select Purchase Type'
            options={getporeqtype?.data?.map((item) => ({
              value: item?.reqtype,
              label: item?.descr,
            }))}
          />
        </Form.Item>
        <Form.Item name='quotnum' label='Quotation number'>
        <Input placeholder='Enter Quotation No.' />
        </Form.Item>
        <Form.Item name='quotationdate' label='Quotation Date'>
          <DatePicker
            // disabledDate={futureDates}
            placeholder='Enter Quotation Date'
            format='DD-MMM, YYYY'
          />
        </Form.Item>
        <Form.Item name='sessionid' label='Session ID'>
          <Select
            placeholder='Select Session ID'
            options={getposession?.data?.map((item) => ({
              value: item?.sessionid,
              label: item?.sessionid,
            }))}
          />
        </Form.Item>
        
        <Form.Item className='' name='descr' label='Description'>
          <Input placeholder='Enter Description' />
        </Form.Item>
      </div>
      
    </Form>
 
  );
};

export default AddPurchaseOrder;
