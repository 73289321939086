import { Form, Input, Select } from 'antd'
import React from 'react'
import { useLovgetponomnlistMutation, useLovgetuomQuery } from '../inventoryService'
import formValidationMessages from '../../../utils/formValidationMessages';

const UpdatePurchaseOrder = ({rowData, UpdatePurchaseOrder }) => {

  const{data:lovgetuom}=useLovgetuomQuery();
  const [lovgetponomnlist]=useLovgetponomnlistMutation();

  const onFinish = (values) =>
  UpdatePurchaseOrder({
      ...values,
      srno: rowData?.srno,
      message: 'B',
    })
  return (
  <Form
    layout='vertical'
    onFinish={onFinish}
    autoComplete='off'
    id='updatePurchaseOrderForm'
    // initialValues={{
    //   ...rowData,
    //   srno: rowData?.orgOrganization?.orgid,
    // }}
    validateMessages={formValidationMessages}
  >
    <div className='grid sm:grid-cols-4 grid-cols-1 gap-2'>
    <Form.Item name='srno' label='Sr.No'>
        <Input placeholder='Enter Sr.No' />
      </Form.Item>
      <Form.Item name='description' label='Description'>
        <Select
          placeholder='Select Desription'
 
          options={lovgetponomnlist?.data?.map((item) => ({
            value: item?.pridescr,
            label: `${item?.pridescr}`,
          }))}
        />
      </Form.Item>
      <Form.Item name='mrp' label='MRP'>
        <Input placeholder='Enter MRP' />
      </Form.Item>
      <Form.Item name='packsize' label='Pack Size'>
        <Input placeholder='Enter Pack Size' />
      </Form.Item>

      <Form.Item name='packqty' label='Qty/Pack '>
        <Input placeholder='Enter Qty/Pack' />
      </Form.Item>

      <Form.Item name='unitqty' label='Unit QTY'>
        <Input placeholder='Enter Unit QTY' />
      </Form.Item>
      <Form.Item name='packrate' label='Pack Rate'>
        <Input placeholder='Enter Pack Rate' />
      </Form.Item>

      <Form.Item name='unitrate' label='Unit Rate'>
        <Input placeholder='Enter Unit Rate' />
      </Form.Item>

      <Form.Item name='discpct' label='Disc %'>
        <Input placeholder='Enter Disc %' />
      </Form.Item>

      <Form.Item name='taxpct' label='Tax %'>
        <Input placeholder='Enter Tax %' />
      </Form.Item>

      <Form.Item name='totalamount' label='Total Amount'>
        <Input placeholder='Enter Total Amount' />
      </Form.Item>

      <Form.Item name='uom' label='UOM'>
        <Select
          placeholder='Select UOM'
          options={lovgetuom?.data?.map((item) => ({
            value: item?.code,
            label: item?.descr,
          }))}
        />
      </Form.Item>
      <Form.Item name='gtotal' label='Grand Total'>
        <Input placeholder='Enter Grand Total' />
      </Form.Item>


      
    </div>
  </Form>
  )
}

export default UpdatePurchaseOrder