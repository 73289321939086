import { DatePicker, Form, Input, Table } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'
import { useGetposcheduleMutation, useSaveschdMutation } from '../inventoryService';
import { useSelector } from 'react-redux';

function AddSchedule({poDetail,onCancel}) {
const co = useSelector((state) => state.auth.user.co);
const userid = useSelector((state) => state.auth.user.empno);
  
const [scheddetail,{data:getscheddetail}]=useGetposcheduleMutation();
const [savesched, { data: Allsavesched }] = useSaveschdMutation();

const  columns = [
    {
      title: "Sched ID",
      dataIndex: "schedid",
      // ...filter("reqnum"),
      // sorter: (a, b) => a?.reqnum?.localeCompare(b?.reqnum),
    },
    {
      title: "QTY(%)",
      dataIndex: "qtyprct",
      // ...filter("regnum"),
      // sorter: (a, b) => a?.regnum?.localeCompare(b?.regnum),
    },

   
    {
      title: "Start Date",
      dataIndex: "startdate",
      
    },
    {
      title: "End Date",
      dataIndex: "enddate",
     
    },
  ];
  const onFinish = (values) => {
    // console.log("values", values);
    if(values)
    {
      savesched({
      ponum: poDetail?.ponum,
      co:co,
      schedid:values?.schedid,
      qtyprct: values?.qtyprct,
      startdate: values?.startdate,
      userid:userid,
      podate:poDetail?.podate,
      poseq:poDetail?.poseq,
      enddate:values?.enddate,
    
    });
    if (savesched){
      onCancel()
    }}
  };
  return (
    <div>
    <Form
     // form={form}
      layout='vertical'
      onFinish={onFinish}
     
    
      autoComplete='off'
      id='addScheduleform'
      validateMessages={formValidationMessages}
    >
      <div className='grid sm:grid-cols-4 grid-cols-1 gap-2'>
    
        <Form.Item name='schedID' label="Sched ID">
          <Input />
        </Form.Item>
        <Form.Item name='qtyprct' label='Qty(%)'>
          <Input />
        </Form.Item>
        
        <Form.Item name='startdate' label='Start Date'>
          <DatePicker
            // disabledDate={futureDates}
            placeholder='Enter Start Date'
            format='DD-MMM-YYYY'
          />
        </Form.Item>
        
        <Form.Item name='enddate' label='End Date'>
          <DatePicker
            // disabledDate={futureDates}
            placeholder='Enter End Date'
            format='DD-MMM-YYYY'
          />
        </Form.Item>


       
      </div>
      
    </Form>
    <div>
      <Table
              columns={columns}
               dataSource={getscheddetail?.data}
              pagination={false}
              rowKey="MR"
               onRow={(record) => ({
               onClick: () => {
                scheddetail({
                  podate:record?.podate,
                  poseq:record?.poseq,
                  ponum:record?.ponum,
                });
                },
              })}
            />
      </div>
    </div>

    
  )
}

export default AddSchedule