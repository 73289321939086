import React, { useEffect, useState } from 'react'
import { useCreatePurchaseOrderDetMutation, useGetInvPurchaseDetailQuery } from '../inventorypurchaseService';
import useTableFilters from '../../../hooks/useTableFilter';
import { Button, Modal, Table, Tooltip } from 'antd';
import AddInvPurchaseDetail from '../components/AddInvPurchaseDetail';
import { EditFilled, PlusOutlined } from '@ant-design/icons';
import UpdateInvPurchaseDetail from '../components/UpdateInvPurchaseDetail';
import moment from 'moment'

const InvPurchaseDetail = () => {
  const { data, isLoading,refetch} = useGetInvPurchaseDetailQuery();
  const [createpodetail, { isSuccess: addSuccess, isLoading: addLoading }] = useCreatePurchaseOrderDetMutation();
  // const [ updatestock, {data:updatestockData, isSuccess: updateSuccess, isLoading: updateLoading }, ] = useUpdateInvStockMutation();
  const [showAddModal, setShowAddModal] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const filter = useTableFilters();
  const[tableDatainfo,setTableDatainfo]=useState([]);
  useEffect(()=>{
      if(data){
        setTableDatainfo(data?.data)
      }
    },[data])
    useEffect(() => {
      if (addSuccess) setShowAddModal(false);
       handlerefetch()
    }, [addSuccess]); // eslint-disable-line

    // useEffect(() => {
    //   if (updateSuccess) setShowUpdateModal(false);
    //   handlerefetch();
    // }, [updateSuccess]); // eslint-disable-line
  const tableColumns = [
    {
        title:"Item Name",
        dataIndex: "descr",
        ...filter("descr"),
     
      },
    {
      title:"Current Price",
      dataIndex: "retailprice",
      ...filter("retailprice"),
   
    },

    {
      title:"Purchase Date ",
      dataIndex: "purchasedate ",
      render:(data)=>(
        <div>
      {  moment(data?.purchasedate)?.format("MM/DD/YYYY") }</div>
      )
     
   
    },
    {
      title:"Item ID",
      dataIndex: "itemid",
      ...filter("itemid"),
   
    },
    {
      title:"Quantity",
      dataIndex: "quantity",
      ...filter("quantity"),
   
    },
    {
      title:"Rate",
      dataIndex: "rate",
      ...filter("rate"),
   
    },
    {
      title:"Unit Rate",
      dataIndex: "unitrate",
      ...filter("unitrate"),
   
    },
    {
      title:"UOM",
      dataIndex: "uomid",
      ...filter("uomid"),
   
    },
    {
      title:"Last Received Quatity",
      dataIndex: "lastrcvqty",
      ...filter("lastrcvqty"),
   
    },
    {
      title:"Current Quantity",
      dataIndex: "currrcvqty",
      ...filter("currrcvqty"),
   
    },
    {
      title:"taxpct",
      dataIndex: "taxpct",
      ...filter("taxpct"),
   
    },
    {
      title:"Discount Packet",
      dataIndex: "discountpct",
      ...filter("discountpct"),
   
    },
    {
      title:"Discount Amount",
      dataIndex: "discountamount",
      ...filter("discountamount"),
   
    },
    {
      title:"Pack Size",
      dataIndex: "packsize",
      ...filter("packsize"),
   
    },
    {
      title:"Extra Quantity",
      dataIndex: "extraqty",
      ...filter("extraqty"),
   
    },

      {
        title: "Action(s)",
        render: (data) => (
          <Tooltip title="Update PO Detail">
            <Button
              type="primary"
              className="btn-warning-primary"
              icon={<EditFilled/>}
              onClick={() => {
                setRowData(data);
                setShowUpdateModal(true);
              }}
            />
          </Tooltip>
        ),
      },
   
   
  ];
    const handlerefetch=()=>{
      refetch()
    }
  return (
    <section>
    <div className="flex items-center justify-between px-5 py-3 ">
      <h1 className="font-semibold text-base sm:text-lg">Inventory PO Detail</h1>
      <Tooltip  title="Add New Stock ">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setShowAddModal(true)}
        />
      </Tooltip>
    </div>
    <div className='p-2'>
      <Table
       columns={tableColumns}
       dataSource={tableDatainfo}
        pagination={{ defaultPageSize: 5 }}
        loading={isLoading}
        bordered
        size='small'
        scroll={{ x: "fit-content" }}
        rowKey='type'
 
        
      />
    </div>
    <Modal
    title="Add PO Detail"
    open={showAddModal}
    onCancel={() => setShowAddModal(false)}
    centered
    width={1000}
    destroyOnClose
    footer={[
      <Button
        key="1"
        htmlType="submit"
        form="addpodetailForm"
        loading={addLoading}
        type="primary"
      >
        Add
      </Button>,
    
    ]}
  >
<AddInvPurchaseDetail
  createpodetail={createpodetail} 
  handlerefetch={handlerefetch}
  />
  </Modal>

  <Modal
      title="Update PO Detail"
      open={showUpdateModal}
      onCancel={() => setShowUpdateModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="updateInvStockForm"
        // loading={updateLoading}
          type="primary"
        >
          Update
        </Button>,
        <Button key="2" onClick={() => setShowUpdateModal(false)}>
          Cancel
        </Button>,
      ]}
    >
      <UpdateInvPurchaseDetail
        rowData={rowData}
        // updatestockData={updatestockData}
        // updatestock={updatestock}
      />
    </Modal>

  </section>
  )
}

export default InvPurchaseDetail