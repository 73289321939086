import { Button, DatePicker, Form, Select, Table } from 'antd'
import React, { useState } from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'
import {  useGetreqmasterqueryQuery, useGetreqstatusQuery, useGetreqtypeQuery, useGetrequestdetaildtoMutation, useGetrequestusersQuery, useGetrequnitQuery } from '../inventoryService';
import useTableFilters from '../../../hooks/useTableFilter';



const SearchRequest=() =>{
  
    const [subGroup, setsubgroup] = useState([]);
    const { data: getrequnitlov } = useGetrequnitQuery();
    const { data: getrequserslov } = useGetrequestusersQuery();
    const { data: getreqtypelov } = useGetreqtypeQuery();
    const { data: getreqstatuslov } = useGetreqstatusQuery();
    const { data, isLoading} = useGetreqmasterqueryQuery();
    const [getreqdetail, { data: reqdetail, isLoading: subLoading }] = useGetrequestdetaildtoMutation();
    // const co = useSelector((state) => state.auth.user.co);
    const filter = useTableFilters();
    const columns=[

        {
            title:"Request Number",
            dataIndex:"reqnum",
            ...filter("reqnum"),
          sorter: (a, b) => a?.reqnum - b?.reqnum,
          defaultSortOrder: "descend",
        },
    
        {
          title:"Request Date",
          dataIndex:"reqdate",
          ...filter("reqdate"),
          sorter: (a, b) => a?.reqdate - b?.reqdate,
          defaultSortOrder: "descend",
       
      },
        {
            title:"Request Type",
            dataIndex:"ir",
            ...filter("ir"),
            sorter: (a, b) => a?.ir - b?.ir,
            defaultSortOrder: "descend",
         

         
        },
        {
            title:"Request From Unit",
            dataIndex:"reqfromunit",
            ...filter("reqfromunit"),
            sorter: (a, b) => a?.reqfromunit - b?.reqfromunit,
            defaultSortOrder: "descend",
         
        },
        {
            title:"User ID",
            dataIndex:"userid",
            ...filter("userid"),
            sorter: (a, b) => a?.userid - b?.userid,
            defaultSortOrder: "descend",
         
        },
        {
            title:"Status",
            dataIndex:"status",
            ...filter("status"),
            sorter: (a, b) => a?.status - b?.status,
            defaultSortOrder: "descend",
         
        },
      ]
      const dataColumns = [
        {
          title: "Material Primary Description",
          dataIndex: "itemname",
        },
    
        {
          title: "Material Code",
          dataIndex: "nomncltre",
        },
    
        {
          title: "Qty Demand",
          dataIndex: "qtydemand",
        },
        {
          title: "Qty Approved",
          dataIndex: "qtyaprvd",
        },
        {
            title: "Qty Issued",
            dataIndex: "qtyissued",
        },
    
        
  ];
  return (
    <section >
    <div>
<h1 className=" col-span-2 flex justify-center items-center font-semibold h-12 text-2xl mt-0">
       Search Request
        </h1>
</div>
      
        <Form
    layout='vertical'
    // onFinish={onFinish}
    autoComplete='off'
    validateMessages={formValidationMessages}
  >
    <div className='grid sm:grid-cols-3 grid-cols-3 gap-2'>
     
    
      <Form.Item name='requser' label='Requesting User'>
      <Select
          placeholder='Search Requesting User'
          allowClear
        showSearch
          filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
          
          options={getrequserslov?.data?.map((item) => ({
            value: item?.userid,
            label: item?.username,
          }))}
        />
      </Form.Item>
      <Form.Item name='fromdate' label='From Date'>
          <DatePicker
            // disabledDate={futureDates}
            placeholder='Enter From Date'
            format='DD-MMM-YYYY'
          />
        </Form.Item>

        <Form.Item name='todate' label='To Date'>
          <DatePicker
            // disabledDate={futureDates}
            placeholder='Enter To Date'
            format='DD-MMM-YYYY'
          />
        </Form.Item>
        <Form.Item name='requnit' label='Requesting Unit'>
      <Select
          placeholder='Select Requesting Unit'
          filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
            options={getrequnitlov?.data?.map((item) => ({
            value: item?.orgunitid,
            label: item?.unitname,
          }))}
        />
      </Form.Item>
      <Form.Item name='ir' label='Request Type'>
      <Select
          placeholder='Select Request Type'
          filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }

          //   onChange={(value)=>{
          //   getmaterial({
          //     co:co,
          //         unit: value,
          //         message: "B",
          //       });
          // }}

          
          options={getreqtypelov?.data?.map((item) => ({
            value: item?.reqtype,
            label: item?.descr,
          }))}
        />
      </Form.Item>
      <Form.Item name='status' label='Status'>
      <Select
          placeholder='Select Status'
          filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
          
          options={getreqstatuslov?.data?.map((item) => ({
            value: item?.status,
            label: item?.descr,
          }))}
        />
      </Form.Item>
    </div>
  </Form>
  <div>
     <Table
        columns={columns}
        dataSource={data?.data}
        pagination={{ defaultPageSize: 3 }}
        loading={isLoading}
        bordered
        size='small'
        
        rowKey='type'
          onRow={(record) => {
            return {
              onClick: () => {
                setsubgroup(record);

                getreqdetail({
                 
                  reqdate:record?.reqdate,
                  reqnum: record?.reqnum,
                

                  message: "B",
                });
              },
            };
          }}
               
          rowClassName={(record) =>
            record === subGroup
              ? "selected-row cursor-pointer"
              : "cursor-pointer"
          }
          rowStyle={(record) =>
            record === subGroup
              ? { backgroundColor: "gray", color: "white" }
              : {}
          }       
        ></Table>

    </div>
    <div className='mt-2'>
        <Table
         dataSource={reqdetail?.data}
          columns={dataColumns}
          pagination={{ defaultPageSize: 3 }}
         loading={subLoading}
        bordered
        size='small'
        //   scroll={{ x: "fit-content" }}
          rowKey='type'
        //   onRow={(record) => {
        //     return {
        //       onClick: () => {
        //         setassessories(record);
        //         getwardaccessories({
        //           unit: record?.unit,
        //           bednum:record?.bednum,
        //         });
        //       },
        //     };
        //   }}
        //   rowClassName={(record) =>
        //     record === bedaccessories
        //       ? "selected-row cursor-pointer"
        //       : "cursor-pointer"
        //   }
        //   rowStyle={(record) =>
        //     record === bedaccessories
        //       ? { backgroundColor: "gray", color: "white" }
        //       : {}
        //   }
        />
      </div>
      <div className='col-span-1 flex mt-2 justify-end gap-2'>
        <Button type='primary' 
        // onClick={()=>{onFinish()}}
        >
          Print Req
        </Button>
        <Button type='primary' 
        // onClick={()=>{onFinish()}}
        >
          WorkFlow
        </Button>
        <Button type='primary' 
        // onClick={()=>{onFinish()}}
        >
          Comments
        </Button>

      </div>
       
    </section>
  )
}

export default SearchRequest