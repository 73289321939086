import { Form, Input, Select } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'

const AddVendorDetails = ({createvendordetail}) => {
    const onFinish = (values) =>{
        createvendordetail({
          ...values,
          message: 'B',
        })
      }
    return (
      <div>
      <Form
      layout='vertical'
   onFinish={onFinish}
   initialValues={{
            weburl: " ",
             fax:" ",
             tel2:" ",
             saletaxnum:" ",
             addr2:" ",
            ntnnum:" ",
            tel1:" ",
          }}
      id='addvendordetailsForm'
      validateMessages={formValidationMessages}
      className='grid grid-cols-5 gap-2'
    >
      <Form.Item
        name='vendorname'
        label='Vendor Name'
        rules={[
          {
            required: true,
          },
        ]}
   >
        <Input placeholder='Enter Vendor Name'/>
      </Form.Item>

      <Form.Item
        name='email'
        label='Email'
        rules={[
          {
            required: true,
          },
        ]}
   >
        <Input placeholder='Enter Email'/>
      </Form.Item>
      <Form.Item
        name='addr1'
        label='Address 1'
        rules={[
          {
            required: true,
          },
        ]}
   >
        <Input placeholder='Enter Address 1'/>
      </Form.Item>

      <Form.Item
        name='addr2'
        label='Address 2'
  
   >
        <Input placeholder='Enter Address 2'/>
      </Form.Item>

      <Form.Item
        name='city'
        label='City '
        rules={[
          {
            required: true,
          },
        ]}
   >
        <Input placeholder='Enter City'/>
      </Form.Item>

      <Form.Item
        name='tel1'
        label='Telephone 1'
   >
        <Input placeholder='Enter Telephone 1'/>
      </Form.Item>

      <Form.Item
        name='tel2'
        label='Telephone 2'
   >
        <Input placeholder='Enter Telephone 2'/>
      </Form.Item>
      <Form.Item
        name='ntnnum'
        label='ntnnum'
   >
        <Input placeholder='Enter ntnnum'/>
      </Form.Item>

      <Form.Item
        name='saletaxnum'
        label='Sale Tax Number'
   >
        <Input placeholder='Enter Sales Tax Number'/>
      </Form.Item>

      <Form.Item
  name='vendorcurrentstatus'
  label='Vendor Current Status'
  initialValue='Y'
>
  <Select
    placeholder='Select Vendor Current Status'
    options={[
      { label: 'Active', value: 'Y' },
      { label: 'In Active', value: 'N' },
    ]}
  />
</Form.Item>

        <Form.Item
        name='fax'
        label='Fax'
   >
        <Input placeholder='Enter Fax'/>
      </Form.Item>
      <Form.Item
        name='weburl'
        label='Web URL'
   >
        <Input placeholder='Enter Web URL'/>
      </Form.Item>
    </Form>
      </div>
    )
  }
  
export default AddVendorDetails