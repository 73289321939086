import { api } from "../api/api";

export const InventoryPurchaseApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInvPurchaseOrder: build.query({
      query: () => `pos/getInvPurchaseOrder`,
    }),

    CreatePurchaseOrder: build.mutation({
      query: (body) => ({
        url: `pos/createPurchaseOrder`,
        method: "POST",
        body,
      }),
    }),

    UpdatePurchaseOrder: build.mutation({
      query: (body) => ({
        url: `pos/updatePurchaseOrder`,
        method: "POST",
        body,
      }),
    }),

    lovVendr: build.query({
      query: (body) => `pos/lovVendr`,
    }),

    getInvStock: build.query({
      query: () => `pos/getInvStock`,
    }),

    createInvStock: build.mutation({
      query: (body) => ({
        url: `pos/createInvStock`,
        method: "POST",
        body,
      }),
    }),

    updateInvStock: build.mutation({
      query: (body) => ({
        url: `pos/updateInvStock`,
        method: "POST",
        body,
      }),
    }),

    getInvPurchaseDetail: build.query({
      query: () => `pos/getInvPurchaseDetail `,
    }),

    lovPurchaseOrder: build.query({
      query: (body) => `pos/lovPurchaseOrder`,
    }),

    lovItemList: build.query({
      query: (body) => `pos/lovItemList `,
    }),

    lovInvUom: build.query({
      query: (body) => `pos/lovInvUom `,
    }),

    CreatePurchaseOrderDet: build.mutation({
      query: (body) => ({
        url: `pos/createPurchaseOrderDet`,
        method: "POST",
        body,
      }),
    }),

    // UpdatePurchaseOrder: build.mutation({
    //   query: body => ({
    //     url: `pos/updatePurchaseOrder`,
    //     method: 'POST',
    //     body,
    //   }),

    // }),
  }),
});

export const {
  useGetInvPurchaseOrderQuery,
  useUpdatePurchaseOrderMutation,
  useCreatePurchaseOrderMutation,
  useLovVendrQuery,
  useGetInvStockQuery,
  useCreateInvStockMutation,
  useUpdateInvStockMutation,
  useGetInvPurchaseDetailQuery,
  useCreatePurchaseOrderDetMutation,
  useLovInvUomQuery,
  useLovItemListQuery,
  useLovPurchaseOrderQuery,
} = InventoryPurchaseApi;
