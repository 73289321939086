import { Button, DatePicker, Form, Input,  Modal,  Table, Tooltip } from 'antd'
import { useGetporeceivingMutation, usePoreceivewebMutation } from '../inventoryService';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import AddBatches from './AddBatches';
import { useState } from 'react';
import { EditFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';


function ItemsReceive() {
  const co = useSelector((state) => state.auth.user.co);
  const userid = useSelector((state) => state.auth.user.empno);
  const [rowData, setRowData] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
 
  const [poreceiving, { data: getpo }] = useGetporeceivingMutation();
  const [poreceive, { data: saveporeceive }] = usePoreceivewebMutation();
  const [selectedIds, setSelectedIds] = useState([]);


    const tableColumns = [
        {
          title: "PO No.",
          dataIndex: "ponum",
         
        },
       
        {
          title: "PO Date",
          dataIndex: "podate",
       
        },

        {
          title: "Seq No.",
          dataIndex: "poseq",
        
        },

        {
          title: "Status",
          dataIndex: "status",
        
        },
        
      ];

      const data = [
  
        {
          title: "Description",
          dataIndex: "itemname",
          
        },
        
        {
          title: "Vendor Name",
          dataIndex: "vendorname",
          
        },
        {
          title: "UOM",
          dataIndex: "uom",
          
        },
        {
          title: "Pack Size",
          dataIndex: "packsize",
          
        },
        {
            title: "Pack Qty Received",
            dataIndex: "packqty",
            
          },

        {
            title: "Qty Received",
            dataIndex: "qtyrcvd",
            
          },
          {
            title: "Qty Approved",
            dataIndex: "qtyaprvd",
            
          },
         
          {
            title: "Unit Rate",
            dataIndex: "rateunit",
            
          },
          {
            title: "Pack Rate",
            dataIndex: "rate",
            
          },
          {
            title: "Disc % ",
            dataIndex: "discpct",
            
          },
  
        {
          title: "Tax %",
          dataIndex: "taxpct",
          
        },
       

        {
          title: "Last Received Quatity ",
          dataIndex: "lastrcvqty",
          
        },

       
        {
          title: "Current Received Quatity",
          dataIndex: "currrcvqty",
          
        },
        {
          title: 'Batched Item',
          key: 'batcheditem',
          render: (text, record) => (
            <Checkbox 
            onChange={(e) => handleCheckboxChange(e,record.id)} 
            checked={selectedIds.includes(record.id)}
            />
          ),
        },
        
        {
          title: "Batches",
          render: (data) => (
            <Tooltip title='Add Batches Items'>
              <Button
                type='primary'
                className='btn-warning-primary'
                icon={<EditFilled />}
                onClick={() => {
                  setRowData(data);
                  setShowUpdateModal(true);
                  handleButtonClick();
                 
                }}
                disabled={isButtonDisabled} 
              />
            </Tooltip>
          ),
        },
        

    
      ];
      const onFinish = (values) => {
        poreceiving({
          ponum: values?.ponum,
        });
        
    
        
      };

      const onFinish1 = (values) => {
        console.log("getpo",getpo)
        poreceive({
          pco: co,
          pponum:getpo?.data[0]?.ponum,
          pposeq:getpo?.data[0]?.poseq,
          ppodate:getpo?.data[0]?.podate,
          punit:getpo?.data[0]?.unit,
          ppostatus:getpo?.data[0]?.status,
          userid: userid,
          pdocnum:123,

        });
        
    
        
      };

      const handleCheckboxChange = (e, id) => {
        if (e.target.checked) {
          // Add the ID to the selectedIds array
          setSelectedIds([...selectedIds, id]);
        } else {
          // Remove the ID from the selectedIds array
          setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
        }
      };
    
      const isButtonDisabled = selectedIds.length === 0;

    const handleButtonClick=() =>{
        // Add your button click logic here
        // alert('Add Batched Items: ' + selectedIds.join(', '));
      }

    

  return (
    <section>
        <div>
<h1 className=" col-span-2 flex justify-center items-center font-semibold h-12 text-2xl mt-0">
        Items Receive 
        </h1>
</div>
    <Form
    layout='vertical'
    onFinish={onFinish}
  >
    <div className=' m-2 mt-0 grid sm:grid-cols-5 grid-cols-1 gap-2'>
    <Form.Item name='ponum' label='PO No.'>
        <Input 
        placeholder='Enter PO No.' />
      </Form.Item>
      
      
      <Form.Item name='podate' label='PO Date'>
          <DatePicker
            // disabledDate={futureDates}
            placeholder='Enter PO Date'
            format='DD-MM-YYYY'
          />
        </Form.Item>

        <Form.Item name='seq' label='Seq No.'>
        <Input 
        placeholder='Enter Seq No.' />
      </Form.Item>

      <Form.Item name='vendor' label='Vendor'>
        <Input 
        placeholder='Enter Vendor' />
      </Form.Item>
      <Form.Item 
      label=' '>
      <Button className='flex items-center justify-end '
      type="primary" htmlType="submit" >
               Search
              </Button> </Form.Item>
      
        
    </div>

  </Form>

  <div className="grid grid-cols-2">
  <div className='m-2 mt-0 '>
      <Table 
        dataSource={getpo?.data}
        columns={tableColumns}
        pagination={{ defaultPageSize: 20 }}
        // loading={isLoading}
        bordered
        size="small"
        // scroll={{ x: 'auto' }}
        rowKey="type"
      />
</div> 

<div className=" border border-gainsboro m-2 mt-0">
        <h1 className="flex justify-center items-center bg-[#3d92bc] font-semibold text-white h-10 ">
          Invoicing
        </h1>
        <Form
          layout="vertical"
        //   onFinish={onFinish}
        //   initialValues={{
        //     acute: "Y",
        //   }}
        >
          <div className="grid grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-2 p-1 py-1 ">
              
              <Form.Item name="invoicenum" label="Invoice Number">
                <Input />
              </Form.Item> 

              <Form.Item name="invoivedate" label="Invoice Date" >
                <DatePicker />
              </Form.Item>
              <Form.Item 
                label="WH Tax Amount">
                <Input 
                disabled
                ></Input>
              </Form.Item>
              <Form.Item name="freight" label="Freight">
                <Input />
              </Form.Item>
              <Form.Item name="misc" label="Misc Exp">
                <Input />
              </Form.Item>
              <Form.Item name="security" label="Security">
                <Input />
              </Form.Item>

             
            </div>
          
        </Form>
        {/* <fieldset>
            <legend>Unit</legend>
            <div>name1</div>
            <div>name2</div>
        </fieldset> */}
      </div>
      </div>
      <div>
      {/* <h1 className="flex justify-center items-center bg-[#3d92bc] font-semibold text-white h-10  m-2">
          Item Record
        </h1> */}
    
        <div className='m-2 mt-0'>
      <Table 
        dataSource={getpo?.data}
        columns={data}
        pagination={{ defaultPageSize: 20 }}
        // loading={isLoading}
        bordered
        size="small"
        // scroll={{ x: 'fit-content' }}
        rowKey="type"
      />
</div> 
      </div>

      <Modal
        title='Batches Detail'
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key='1'
            htmlType='submit'
            form='addBatchesItem'
            // loading={updateLoading}
            type='primary'
          >
           Add
          </Button>,
          <Button key='2' onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddBatches
          rowData={rowData}
          
        />
      </Modal>


      <Form layout='vertical'>    
<div className='grid grid-cols-3 gap-2  p-2'>
  <div className='grid grid-cols-2 gap-2 border border-gainsboro p-2'>
  <Form.Item  name='nomnclture' label='Nomnclture'>
          <Input  />
        </Form.Item>
<Form.Item  name='rack' label='Rack'>
          <Input  />
        </Form.Item>
        </div>


  <div className='grid grid-cols-3 gap-2 border border-gainsboro p-2'>  
  
        
  <Form.Item  name='orderedqty' label='Ordered Qty'>
          <Input  />
        </Form.Item>
      
            <Form.Item  name='previousqty' label='Previous Qty'>
          <Input  />
        </Form.Item>
            
           
            <Form.Item  name='remainingqty' label='Remaining Qty'>
          <Input  />
        </Form.Item>
        </div>
          
       


           
            <div className='grid grid-cols-3 gap-2 border border-gainsboro p-2'>
  <Form.Item  name='orderedqty' label='Ordered Qty'>
          <Input  />
        </Form.Item>
         
            <Form.Item  name='previousqty' label='Previous Qty'>
          <Input  />
        </Form.Item>
            
           
            <Form.Item  name='remainingqty' label='Remaining Qty'>
          <Input  />
        </Form.Item>
           

</div>
</div>
</Form>
<div className="col-span-1 flex items-center  justify-end p-2">
        <Button className="mr-2" type="primary">
          Item Detail
        </Button>

        <Button
          className="mr-2"
          type="primary"
          htmlType="submit"
          
        >
          Print PO
        </Button>
        <Button 
         className="mr-2" type="primary" htmlType="submit">
          Show GRN
        </Button>
        <Button type="primary" onClick={()=>{onFinish1()}}>
         Received
        </Button>
      </div>


  </section>
  )
}

export default ItemsReceive