import { EditFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Table, Tooltip } from "antd";
import React, { useState } from "react";
import AddPurchaseOrder from "./AddPurchaseOrder";
import UpdatePurchaseOrder from "./UpdatePurchaseOrder";
import {
  useGetPoDetailListDtoMutation,
  useGetpolistQuery,
  usePoorderedMutation,
} from "../inventoryService";
import AddPoDetails from "./AddPoDetails";
import { useSelector } from "react-redux";
import AddSchedule from "./AddSchedule";


function PurchaseOrder() {
  const [poDetail, setPoDetail] = useState([]);
  const co = useSelector((state) => state.auth.user.co);
  const userid = useSelector((state) => state.auth.user.empno);
  
  const [showAddScheduleModal, setShowAddScheduleModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDetailModal, setDetailModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const { data: getpolist } = useGetpolistQuery();
  const [rowData, setRowData] = useState(false);
  const [poordered, { data: savepoordered }] = usePoorderedMutation();
 
  const [getPoDetail, { data: getPoDetailData, isLoading: poLoading }] =useGetPoDetailListDtoMutation();
    
  const [form] = Form.useForm();
  const tableColumns = [
    {
      title: "PO No.",
      dataIndex: "ponum",
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorname",
    },
    {
      title: "PO Date",
      dataIndex: "podate",
    },

    {
      title: "Purchase Type",
      dataIndex: "reqtype",
    },
    {
      title: "Quotation Date",
      dataIndex: "quotdate",
    },
    {
      title: "Session ID",
      dataIndex: "sessionid",
    },
    {
      title: "Description",
      dataIndex: "descr",
    },
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title='Add Schedule'>
          <Button
            type='primary'
            className='btn-warning-primary'
            icon={<PlusOutlined />}
            onClick={() => {
              setRowData(data);
              setShowAddScheduleModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];
  // const dataSource = [
  //   {
  //     sr:1,
  //     desc: 'Abc',
  //     mrp: 25,

  //   },
  // ];
  const data = [
    {
      title: "Sr. No",
      dataIndex: "srno",
    },

    {
      title: "Description",
      dataIndex: "description",
    },

    {
      title: "Pack Size",
      dataIndex: "packsize",
    },

    {
      title: "Qty/Pack",
      dataIndex: "packqty",
    },
    {
      title: "Unit Qty",
      dataIndex: "unitqty",
    },
    {
      title: "Pack Rate",
      dataIndex: "packrate",
    },
    {
      title: "Unit Rate",
      dataIndex: "unitrate",
    },
    {
      title: "Disc %",
      dataIndex: "discpct",
    },
    {
      title: "Tax %",
      dataIndex: "taxpct",
    },
    {
      title: "Total Amount ",
      dataIndex: "totalamount",
    },
    {
      title: "UOM",
      dataIndex: "uom",
    },

    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title='PO Detail'>
          <Button
            type='primary'
            className='btn-warning-primary'
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdateModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];
  const handleDelete=(ponum)=>{
    const updatedData = data.filter((row) => row.ponum !== ponum);
    poDetail(updatedData);
  }
  const onFinish = (values) => {
    poordered({
      co: co,
      ponum: poDetail?.ponum,
      poseq: poDetail?.poseq,
      podate: poDetail?.podate,
      userid: userid,

    });
    handleDelete(poDetail?.ponum)

    
  };
  return (
    <section>
      <div>
        <h1 className=' col-span-2 flex justify-center items-center font-semibold h-12 text-2xl mt-0'>
          Purchase Order
        </h1>
      </div>

      <Form
        layout='vertical'
        form={form}
        id='addPurchaseOrderForm'
        onFinish={onFinish}
      >
        <div className='grid grid-cols-4 md:grid-cols-5 sm:grid-cols-2 gap-2 p-2 pb-0 '>
          <Form.Item className=' col-span-3' label='Unit'
          >
            <Input></Input>
          </Form.Item>

          <Form.Item className='col-span-2' label='Seq Num'>
            <Input disabled></Input>
          </Form.Item>
        </div>
      </Form>
      <div className='flex items-center justify-end px-5 py-3 pt-0 mt-0'>
        <Tooltip title='Add Purchase Order '>
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>

      <Modal
        title='Add Purchase Order '
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key='1'
            htmlType='submit'
            form='addPurchaseOrderForm'
            // loading={addLoading}
            type='primary'
          >
            Add
          </Button>,
          <Button key='2' onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddPurchaseOrder setPoDetail={setPoDetail} onCancel={() => setShowAddModal(false)} />
      </Modal>

      <div>
        <Table
          dataSource={getpolist?.data}
          columns={tableColumns}
          pagination={{ defaultPageSize: 5 }}
          // loading={isLoading}
          bordered
          size='small'
          scroll={{ x: "fit-content" }}
          rowKey='type'
          onRow={(record) => {
            return {
              onClick: () => {
                setPoDetail(record);

                getPoDetail({
                  ponum: record?.ponum,
                  poseq: record?.poseq,
                  // podate: record?.podate,

                  message: "B",
                });
              },
            };
          }}
          rowClassName={(record) =>
            record === poDetail
              ? "selected-row cursor-pointer"
              : "cursor-pointer"
          }
          rowStyle={(record) =>
            record === poDetail
              ? { backgroundColor: "gray", color: "white" }
              : {}
          }
          // onRow={(record) => ({
          //   onClick: () => {
          //     setPoDetail(record);
          //   },
          // })}
        />
      </div>
      <Modal
        title='Add Schedule'
        open={showAddScheduleModal}
        onCancel={() => setShowAddScheduleModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key='2'
            htmlType='submit'
            form='addScheduleform'
            // loading={updateLoading}
            type='primary'
          >
           Add
          </Button>,
          <Button key='2' onClick={() => setShowAddScheduleModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddSchedule poDetail={poDetail} onCancel={() => setShowAddScheduleModal(false)}
          rowData={rowData}
          
        />
      </Modal>






      <div className='flex items-center justify-end px-5 py-3 pt-0 mt-0'>
        <Tooltip title='Add PO Details '>
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => setDetailModal(true)}
          />
        </Tooltip>
      </div>
      <Modal
        title='Add PO Details '
        open={showDetailModal}
        onCancel={() => setDetailModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key='1'
            htmlType='submit'
            form='addPODetailform'
            // loading={addLoading}
            type='primary'
          >
            Add
          </Button>,
          <Button key='3' onClick={() => setDetailModal(false)}>
            Cancel
          </Button>,
        ]}
      >


        <AddPoDetails poDetail={poDetail} onCancel={() => setDetailModal(false)} />
      </Modal>
      <div>
        <Table
          // dataSource={data?.data}
          dataSource={getPoDetailData?.data}
          columns={data}
          onFinish={onFinish}
          id='savedata'
          pagination={{ defaultPageSize: 20 }}
          // loading={isLoading}
          bordered
          size='small'
          scroll={{ x: "fit-content" }}
          rowKey='type'
        />
      </div>

      <Modal
        title='PO Detail'
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key='1'
            htmlType='submit'
            form='addPODetailform'
            // loading={updateLoading}
            type='primary'
          >
            Update
          </Button>,
          <Button key='2' onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdatePurchaseOrder
          rowData={rowData}
          UpdatePurchaseOrder={UpdatePurchaseOrder}
        />
      </Modal>

      <div className='col-span-1 flex mt-2 justify-end'>
        <Button type='primary' onClick={()=>{onFinish()}}>
          Place Order
        </Button>
      </div>
    </section>
  );
}

export default PurchaseOrder;
