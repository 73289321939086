import { Tabs } from 'antd'
import React from 'react'
import InventoryBrand from '../components/InventoryBrand'
import InventoryClass from '../components/InventoryClass'
// import GenGroup from '../components/GenGroup'
// import MainGroup from '../components/MainGroup'
import Manufacturer from '../components/Manufacturer'
import InventoryType from '../components/InventoryType'
import InventoryStrength from '../components/InventoryStrength'
import InventoryUOM from '../components/InventoryUOM'

import InvoiceNature from '../components/InvoiceNature'


function InventoryConfiguration() {
    return (
        <section className='border border-gainsboro'>
        <div  className="flex items-center justify-between px-5 py-3"
        style={{  backgroundColor: "#00b29c" }}
        >
          <h1 className='font-semibold text-base sm:text-lg text-white'>
           Inventory Configurations
          </h1>
        </div>
        <div className='p-5'>
          <Tabs
           items={[
              
              {label:' Brand',key:'1',children:<InventoryBrand/>},
              {label:' Class',key:'2',children:<InventoryClass/>},
              //  {label:'Generic Group',key:'3',children:<GenGroup/>},
              // {label:'Main Group',key:'4',children:<MainGroup/>},
              {label:'Manufacturer',key:'5',children:<Manufacturer/>},
              {label:'Nature',key:'6',children:<InvoiceNature/>},
              {label:' Type',key:'7',children:<InventoryType/>},
              {label:'Strength',key:'8',children:<InventoryStrength/>},
              {label:'UOM',key:'9',children:<InventoryUOM/>},
            
              
              
              
             
             
              
            ]}
          />
        </div>
      </section>
      )
}

export default InventoryConfiguration