import { DatePicker, Form, Radio, Select, Table, Tag } from 'antd'
import React, { useState } from 'react'

const DemandGeneration=()=> {
  const [value, setValue] = useState(1); // Initial value for the radio button

  const onChange = e => {
    setValue(e.target.value);
  }
    const Columns = [
        {
          title: "Demand ID",
          dataIndex: "demandid",
          
        },
        {
          title: "Description",
          dataIndex: "descr",
     
        },
       
        {
          title: "Unit",
          dataIndex: "unit",
     
        },
        {
          title: "Department",
          dataIndex: "depart",
     
        },
       
        {
          title: "Class",
          dataIndex: "class",
     
        },
        {
          title: "Demand Date",
          dataIndex: "demanddate",
     
        },
        {
            title: "Remarks",
            dataIndex: "remarks",
       
        },

        {
            title: "Status",
            render: (_, { status }) =>
              status === "Y" ? (
                <Tag color="success">Active</Tag>
              ) : (
                <Tag color="error">In Active</Tag>
              ),
            // sorter: (a, b) => a?.status?.localeCompare(b?.status),
          },
    
    
        
      ];
    const Datacolumns = [
        {
          title: "Seq No.",
          dataIndex: "Seqno",
          
        },
        {
          title: "Description",
          dataIndex: "descr",
     
        },
        {
            title: "Nomncltre",
            dataIndex: "nomn",
        },
        {
            title: "Demand ID",
            dataIndex: "demandid",
       
        },
       
        {
          title: "Type",
          dataIndex: "type",
     
        },
        {
          title: "Strength",
          dataIndex: "strength",
     
        },
       
        {
          title: "UOM",
          dataIndex: "uom",
     
        },
        {
          title: "Stock",
          dataIndex: "stock",
     
        },
        {
            title: "Dues in",
            dataIndex: "duesin",
       
        },
        {
            title: "Min Par Level",
            dataIndex: "minparlev",
       
        },
        {
            title: "Average Consumption",
            dataIndex: "avergconsump",
       
        },
        {
            title: "Qty Demand",
            dataIndex: "qtydemand",
       
        },
        {
            title: "Approved Qty",
            dataIndex: "appqty",
       
        },
        {
            title: "Price",
            dataIndex: "price",
       
        },
        {
            title: "Remarks",
            dataIndex: "remarks",
       
        },

        {
            title: "Default",
            render: (_, { status }) =>
              status === "Y" ? (
                <Tag color="success">Yes</Tag>
              ) : (
                <Tag color="error">No</Tag>
              ),
            // sorter: (a, b) => a?.status?.localeCompare(b?.status),
          },
          {
            title: "Completed",
            render: (_, { status }) =>
              status === "Y" ? (
                <Tag color="success">Yes</Tag>
              ) : (
                <Tag color="error"> No</Tag>
              ),
            // sorter: (a, b) => a?.status?.localeCompare(b?.status),
          },
          {
            title: "Rejected",
            render: (_, { status }) =>
              status === "Y" ? (
                <Tag color="success">Yes</Tag>
              ) : (
                <Tag color="error">No</Tag>
              ),
            // sorter: (a, b) => a?.status?.localeCompare(b?.status),
          },        
      ];
  return (
    <section>
        <div>
        <h1 className=' col-span-2 flex justify-center items-center font-semibold h-12 text-2xl mt-0'>
          Demand Generation
        </h1>
      </div>
      <div className='grid grid-cols-4 gap-2 p-2'>
      <fieldset className='col-span-3'>
      <legend>Search</legend>

      <Form layout='vertical' >
<div className=' m-2 mt-0 grid sm:grid-cols-3 grid-cols-1 gap-2'>
      <Form.Item name='fromdate' label='From Date'>
          <DatePicker
            // disabledDate={futureDates}
            placeholder='Enter From Date'
            format='DD-MM-YYYY'
          />
        </Form.Item>

        <Form.Item name='todate' label='To Date'>
          <DatePicker
            // disabledDate={futureDates}
            placeholder='Enter To Date'
            format='DD-MM-YYYY'
          />
        </Form.Item>
        <Form.Item className='px-9'   name='ConsumptionMethod' label='Consumption Method'>
        <Radio.Group onChange={onChange} value={value}>
      <Radio value={1}>Average</Radio>
      <Radio value={2}>Par Level</Radio>
      
    </Radio.Group>
        </Form.Item>

        <Form.Item name='demandid' label='Demand ID'>
          <Select
            placeholder='Select Demand ID'
            // onChange={(value) => {
            //   savevendor({
            //     //  co:co,
            //     unit: "MAINMEDSTR",
            //     vendorid: value,
            //   });
            // }}
            // options={getvendorlist?.data?.map((item) => ({
            //   value: item?.vendorid,
            //   label: item?.vendordescr,
            // }))}
          />
        </Form.Item>
        <Form.Item name='class' label='Class'>
          <Select
            placeholder='Select Class'
            // onChange={(value) => {
            //   savevendor({
            //     //  co:co,
            //     unit: "MAINMEDSTR",
            //     vendorid: value,
            //   });
            // }}
            // options={getvendorlist?.data?.map((item) => ({
            //   value: item?.vendorid,
            //   label: item?.vendordescr,
            // }))}
          />
        </Form.Item>
        
    <Form.Item  className="px-9"     name='demandbase' label='Demand Base'>
        <Radio.Group onChange={onChange} value={value}>
      <Radio value={1}>Generic Wise</Radio>
      <Radio value={2}>Brand Wise</Radio>
      
    </Radio.Group>
        </Form.Item>
</div>
</Form>



</fieldset>

<fieldset>
    <legend> Average Calculations</legend>
    <Form layout='vertical' >
<div className=' m-2 mt-0 grid sm:grid-cols-1 grid-cols-1 gap-2'>
      <Form.Item name='fromdate' label='From Date'>
          <DatePicker
            // disabledDate={futureDates}
            placeholder='Enter From Date'
            format='DD-MM-YYYY'
          />
        </Form.Item>

        <Form.Item name='todate' label='To Date'>
          <DatePicker
            // disabledDate={futureDates}
            placeholder='Enter To Date'
            format='DD-MM-YYYY'
          />
        </Form.Item>
        
</div>
</Form>
</fieldset>


</div>

<div className="p-2">
      <Table
        // dataSource={onhanddetail?.data}
        columns={Columns}
        pagination={{ defaultPageSize: 5 }}
        // loading={ghLoading}
        bordered
        size="small"
        rowKey="type"
      /> 
</div>
<div className='p-2'>
<Table  virtual scroll={{ x: 2000 }}
        // dataSource={onhanddetail?.data}
        columns={Datacolumns}
        pagination={{ defaultPageSize: 5 }}
        // loading={ghLoading}
        bordered
        size="small"
        rowKey="type"
      /> 
</div>
    </section>
  )
}

export default DemandGeneration