import {DatePicker, Form, Input, Select } from 'antd';
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages';

function AddBatches() {
  return (
    <Form
    //   form={form}
      layout='vertical'
    //   onFinish={onFinish}
      autoComplete='off'
      id='addScheduleform'
      validateMessages={formValidationMessages}
    >
      <div className='grid sm:grid-cols-3 grid-cols-1 gap-2'>
        <Form.Item  name='descr' label='Primary Description'>
          <Input placeholder='Enter Description' />
        </Form.Item>
        <Form.Item  name='totqty' label='Qty Already Received'>
          <Input placeholder='Enter Qty Received' />
        </Form.Item>
        <Form.Item  name='currrecqty' label='Current Receiving Qty'>
          <Input placeholder='Enter Current Receiving Qty' />
        </Form.Item>
      </div>
      <div className='grid sm:grid-cols-4 grid-cols-1 gap-2'>
      <Form.Item  name='batchno' label='Batch No.'>
          <Input placeholder='Enter Batch No.' />
        </Form.Item>
        <Form.Item name='manufacturedate' label='Manufacturing Date'>
          <DatePicker
            // disabledDate={futureDates}
            placeholder='Enter Manufacturing Date'
            format='DD-MMM-YYYY'
          />
        </Form.Item>
        <Form.Item name='expirydate' label='Expiry Date'>
          <DatePicker
            // disabledDate={futureDates}
            placeholder='Enter Expiry Date'
            format='DD-MMM-YYYY'
          />
        </Form.Item>
        <Form.Item  name='tquatity' label='Total Quatity'>
          <Input placeholder='Enter Total Quantity' />
        </Form.Item>
        <Form.Item  name='quatity' label='Quatity'>
          <Input placeholder='Enter Quantity' />
        </Form.Item>
        <Form.Item  name='descr' label='Description'>
          <Input placeholder='Enter Description' />
        </Form.Item>
        <Form.Item
                name="status"
                label="Status"
              
              >
                <Select
                  placeholder="Select"
                  options={[
                    { value: "A", label: "Active" },
                    { value: "I", label: "InActive" },
                  ]}
                />
              </Form.Item>
      </div>

    </Form>
  )
}

export default AddBatches