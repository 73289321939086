import { notification } from 'antd'

const openNotification = (type, message, duration = 4.5) => {
	notification[type]({
		message,
		duration,
	})
}

export default openNotification
