import { DatePicker, Form, Input, Select } from 'antd'
import React from 'react'
// import { futureDates } from '../../../utils/dateUtils';
import formValidationMessages from '../../../utils/formValidationMessages'
import { useLovVendrQuery } from '../inventorypurchaseService'

const AddPurchaseOrder = ({createpo}) => {
  const onFinish = (values) =>{
    createpo({
        ...values,
        message: 'B',
      })
    }
    const { data: lovVender } = useLovVendrQuery();
  return (
    <div>
    <Form
    layout='vertical'
     onFinish={onFinish}
    id='addPOForm'
    validateMessages={formValidationMessages}
    className='grid grid-cols-4 gap-2'
  >
   <Form.Item
              name='vendorid'
              label="Vender Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Select Vender Name"
                options={lovVender?.data?.map((item) => ({
                  value: item?.code,
                  label: `${item?.descr}`,
                }))}
                showSearch
                filterOption={(input, option) =>
                  option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                }
              />
            </Form.Item>

    <Form.Item
      name='purchasenum'
      label='Purchase No.'
      rules={[
        {
          required: true,
        },
      ]}
 >
      <Input placeholder='Enter Purchase No. '/>
    </Form.Item>

    <Form.Item
      name='descr'
      label='Description'
      rules={[
        {
          required: true,
        },
      ]}
 >
      <Input placeholder='Enter Description'/>
    </Form.Item>

    <Form.Item required
      name='purchasedate' label='Purchase Date'>
          <DatePicker
          // disabledDate={futureDates}
            placeholder='Enter Purchase Date'
            format='YYYY-MM-DD'
          />
        </Form.Item>

    <Form.Item
      name='quotnum'
      label='Quotation No.'
      rules={[
        {
          required: true,
        },
      ]}
 >
      <Input placeholder='Enter Quotation No.'/>
    </Form.Item>

    <Form.Item required
      name='quotdate' label='Quotation Date'>
          <DatePicker
          // disabledDate={futureDates}
            placeholder='Enter Quotation Date'
            format='YYYY-MM-DD'
          />
        </Form.Item>
    
        <Form.Item
  name='status'
  label='Status'
  initialValue='Y'
>
  <Select
    placeholder='Select Status'
    options={[
      { label: 'Active', value: 'Y' },
      { label: 'In Active', value: 'N' },
    ]}
  />
</Form.Item>

   
  </Form>
    </div>
  )
}

export default AddPurchaseOrder