import { Form, Input, Select } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'

const UpdateInvoiceNature = ({rowData,updatenature,updatenatureData}) => {
    const [form] = Form.useForm()
    const onFinish = (values) =>
        updatenature({
        ...values,
        genericname:values?.natname,
        status: values?.status,
        cid:values?.natid,
     
        message: 'B',
      })
    return (
  
      <div>
      <Form
      layout='vertical'
      onFinish={onFinish}
      id='updatenatureForm'
      form={form}
      initialValues={{
        natname:rowData?.natname,
        natid:rowData?.natid,
       
        status:rowData?.status,
        
        
       
      }}
      validateMessages={formValidationMessages}
      className='grid grid-cols-3 gap-2'
    >
  
  <Form.Item
        name='natid'
        label='Nature ID'
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input disabled='true' />
      </Form.Item>
      <Form.Item
        name='natname'
        label='Inventory Nature Name'
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder='Enter Inventory Nature Name'  />
      </Form.Item>
      <Form.Item name='status' label='Status'>
          <Select
       
            placeholder='Select Status'
            options={[
              { label: 'Active', value: 'Y' },
              { label: 'In Active', value: 'N' },
            ]}
            
          />
        </Form.Item>
     
    </Form>
      </div>
    )
  }

export default UpdateInvoiceNature