import { DatePicker, Form, Input, Select } from 'antd';
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages';

const UpdateDemand=()=> {
  const [form] = Form.useForm();
  return (
    <Form
    form={form}
    layout='vertical'
    // onFinish={onFinish}
    autoComplete='off'
    id='updateMDemandForm'
    validateMessages={formValidationMessages}
  >
    <div className='grid sm:grid-cols-4 grid-cols-1 gap-2'>
     
      <Form.Item name='demandid' label='Demand ID'
    
      >
        <Input placeholder='Enter Demand ID' />
      </Form.Item>

      <Form.Item name='descr' label='Description'>
        <Input placeholder='Enter Description'></Input>
      </Form.Item>
      <Form.Item name='unit' label='Transaction Unit'>
        <Select
          placeholder='Select Transaction Unit'
//           options={getunitlov?.data?.map((item) => ({
//             value: item?.orgunitid,
//             label: item?.unitname
// ,
//           }))}
        />
      </Form.Item>
      <Form.Item name='requnit' label='Request Unit'>
        <Select
          placeholder='Select Request Unit'
//           options={getunitlov?.data?.map((item) => ({
//             value: item?.orgunitid,
//             label: item?.unitname
// ,
//           }))}
        />
      </Form.Item>
      <Form.Item name='demanddate' label='Demand Date'>
        <DatePicker
          // disabledDate={futureDates}
          placeholder='Enter Demand Date'
          format='DD-MMM-YYYY'
        />
      </Form.Item>
      <Form.Item name='rejecteddemand' label='Rejected Demand'>
      <Input placeholder='Enter Rejected Demand' />
      </Form.Item>
      
      <Form.Item name='remarks' label='Remarks'>
        <Input placeholder='Enter Remarks' />
      </Form.Item>
      <Form.Item name='status' label='Status'>
        <Select
          placeholder='Select Status'
          options={[
            { label: 'Active', value: 'Y' },
            { label: 'In Active', value: 'N' },
          ]}
          
        />
      </Form.Item>
    </div>
    
  </Form>

);
};
export default UpdateDemand