import { DatePicker, Form, Input, Select } from "antd";
import React, { useEffect } from "react";
import formValidationMessages from "../../../utils/formValidationMessages";
import {
  useLovHmsBloodGroupQuery,
  useLovHmsCityQuery,
  useLovHmsCountryQuery,
  useLovHmsDepartmentQuery,
  useLovHmsDesignationQuery,
  useLovHmsMaritalStatusQuery,
  useLovHmsReligionQuery,
  useLovHmsTitleQuery,
} from "../employeeServices";
import moment from "moment";
import { CameraFilled } from "@ant-design/icons";
import avatar from "../../../assets/images/avatar.jpg";
import useBase64 from "../../../hooks/useBase64";
const AddEmpreg = ({ saveEmpreg, empregSuccess }) => {
  const [form] = Form.useForm();
  const { data: getlovCity } = useLovHmsCityQuery();
  const { data: lovhmsCountry } = useLovHmsCountryQuery();
  const { data: getlovmstatus } = useLovHmsMaritalStatusQuery();
  const { data: getlovreligion } = useLovHmsReligionQuery();
  const { data: getlovdesignation } = useLovHmsDesignationQuery();
  const { data: getlovdept } = useLovHmsDepartmentQuery();
  const { data: getlovtitle } = useLovHmsTitleQuery();
  const { data: getlovbloodgrp } = useLovHmsBloodGroupQuery();
  const [imageData, getBase64, clearImageData] = useBase64();
  useEffect(() => {
    if (empregSuccess) {
      form.resetFields();
      clearImageData();
    }
  }, [empregSuccess]); // eslint-disable-line
  const onFinish = (values) => {
    saveEmpreg({
      titleid: values?.titleid || "",
      fname: values?.fname,
      mname: values?.mname || "",
      lname: values?.lname || "",
      attid: values?.attid,
      fathername: values?.fathername,
      dob: moment(values?.dob).format("YYYY-MM-DD"),
      nokrelid: values?.nokrelidy || "",
      nokname: values?.nokname || "",
      nokcnic: values?.nokcnic || "",
      nokmobile: values?.nokmobile || "",
      // nokrelid: values?.nokrelid || "",
      bloodid: values?.bloodid || "",
      orgid: "1",
      deptid: values?.deptid,
      desigid: values?.desigid,
      doj: moment(values?.doj).format("YYYY-MM-DD"),
      salary: values?.salary,
      cnic: values?.cnic,
      cnicexpiry: moment(values?.cnicexpiry).format("YYYY-MM-DD"),
      mobile: values?.mobile,
      gender: values?.gender,
      email: values?.email,
      address: values?.address,
      emptype: values?.emptype || "Regular",
      religionid: values?.religionid,
      marstatus: values?.marstatus,
      cityid: values?.cityid,
      picture: imageData?.data || "",
    });
  };
  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        id="addempreg"
        validateMessages={formValidationMessages}
        initialValues={{
          gender: "M",
          emptype: "Regular",
          religionid: 1,
          marstatus: 1,
          cityid: 1,
          cntryid: "1",
          orgid: "Awan Store",
          deptid: 1,
          bloodid: 1,
        }}
        className="grid sm:grid-cols-4 grid-cols-2 gap-2"
      >
        <div className="flex gap-2">
          <img
            src={imageData ? imageData?.data : avatar}
            alt="patient"
            className="h-[7rem] object-cover"
          />
          <input
            type="file"
            id="file"
            className="hide"
            accept="image/jpg, image/jpeg, image/png"
            onChange={(e) => getBase64(e)}
          />
          <label htmlFor="file" className="cursor-pointer flex gap-0.5">
            <CameraFilled className="text-2xl text-primary hover:text-primaryHover duration-200" />
          </label>
        </div>
        <Form.Item name="titleid" label="Titleid">
          <Select
            placeholder="Select Title"
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
            allowClear
            options={getlovtitle?.data?.map((item) => ({
              value: item?.code,
              label: item?.descr,
            }))}
          />
        </Form.Item>
        <Form.Item name="fname" label="First Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="mname" label="Middle Name">
          <Input />
        </Form.Item>
        <Form.Item name="lname" label="Last Name">
          <Input />
        </Form.Item>
        <Form.Item name="attid" label="Att Id" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="fathername"
          label="Father Name"
          rules={[{ required: true }]}
        >
          {/*by default -*/}
          <Input />
        </Form.Item>
        <Form.Item
          name="dob"
          label="Date&nbsp;of&nbsp;Birth"
          rules={[{ required: true }]}
        >
          {/*formate (yyyy-mm-dd)*/}
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item name="nokrelid" label="Nokrelid">
          <Input />
        </Form.Item>
        <Form.Item name="nokname" label="Nokname">
          <Input />
        </Form.Item>
        <Form.Item name="nokmobile" label="Nokmobile">
          <Input />
        </Form.Item>
        <Form.Item name="bloodid" label="Blood Group">
          <Select
            placeholder="Select Blood Group"
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
            allowClear
            options={getlovbloodgrp?.data?.map((item) => ({
              value: item?.code,
              label: item?.descr,
            }))}
          />
        </Form.Item>
        <Form.Item name="orgid" label="Organization">
          <Input />
        </Form.Item>
        <Form.Item name="deptid" label="Deptid" rules={[{ required: true }]}>
          <Select
            placeholder="Select City"
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
            options={getlovdept?.data?.map((item) => ({
              value: item?.code,
              label: item?.descr,
            }))}
            allowClear
          />
        </Form.Item>
        <Form.Item name="desigid" label="Designation">
          <Select
            placeholder="Select Designation"
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
            options={getlovdesignation?.data?.map((item) => ({
              value: item?.code,
              label: item?.descr,
            }))}
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="doj"
          label="Date&nbsp;of&nbsp;Join"
          rules={[{ required: true }]}
        >
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item name="salary" label="Salary" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="cnic"
          label="CNIC"
          type="number"
          rules={[
            {
              required: true,
            },
            {
              pattern: /^[0-9]{13}$/,
            },
          ]}
        >
          <Input placeholder="3740512345678" maxLength={13} />
        </Form.Item>
        <Form.Item
          name="cnicexpiry"
          label="Cnicexpiry"
          rules={[{ required: true }]}
        >
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item
          name="mobile"
          label="Mobile"
          type="number"
          rules={[
            {
              required: true,
            },
            {
              pattern: /^[0][3][0-9]{9}$/,
            },
          ]}
        >
          <Input placeholder="033312345678" maxLength={11} />
        </Form.Item>
        <Form.Item name="gender" label="Gender">
          {/*by default Male*/}
          <Select
            placeholder="Select Gender"
            options={[
              { label: "Other", value: "O" },
              { label: "Male", value: "M" },
              { label: "Female", value: "F" },
            ]}
          />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="address" label="Address" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="emptype" label="Employee type">
          {/*by default Regular*/}
          <Input />
        </Form.Item>
        <Form.Item name="religionid" label="Religion ID">
          {/*by default Islam*/}
          <Select
            placeholder="Select Description"
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
            options={getlovreligion?.data?.map((item) => ({
              value: item?.code,
              label: item?.descr,
            }))}
            allowClear
          />
        </Form.Item>
        <Form.Item name="marstatus" label="Marital Status">
          {/*by default first*/}
          <Select
            placeholder="Select Description"
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
            options={getlovmstatus?.data?.map((item) => ({
              value: item?.code,
              label: item?.descr,
            }))}
            allowClear
          />
        </Form.Item>
        <Form.Item name="cityid" label="City ID">
          {/*by default first*/}

          <Select
            placeholder="Select City"
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
            options={getlovCity?.data?.map((item) => ({
              value: item?.code,
              label: item?.descr,
            }))}
            allowClear
          />
        </Form.Item>
        <Form.Item name="cntryid" label="Country">
          <Select
            placeholder="Select Country id"
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
            options={lovhmsCountry?.data?.map((item) => ({
              value: item?.code,
              label: item?.descr,
            }))}
            allowClear
          />
        </Form.Item>
        {/* <Form.Item name="status" label="Status">
          <Select
            placeholder="Select Status"
            options={[
              { label: "Active", value: "Y" },
              { label: "In Active", value: "N" },
            ]}
          />
        </Form.Item> */}
      </Form>
    </div>
  );
};

export default AddEmpreg;
