import { Form, Input, Select } from 'antd';
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages';
import { useLovInvBrandsQuery, useLovInvClassQuery,  useLovInvManufactureQuery, useLovInvStrengthQuery, useLovInvTypeQuery, useLovInvUomQuery, useLovNatQuery } from '../inventoryConfiguratorsService';

const UpdateInventoryCode = ({rowData,updateinvcodeData,updateinvcode}) => {
  console.log(rowData)
  const [form] = Form.useForm()
  const onFinish = (values) =>
    updateinvcode({
      ...values,
      itemid:rowData?.itemid,
    
    
   
      message: 'B',
    })
return (
  <div>
  <Form
  layout='vertical'
   onFinish={onFinish}
  id='updateinvcodeForm'
  form={form}
  initialValues={{
      itemname:rowData?.itemname,
      price:rowData?.price,
      status:rowData?.status,

    }}
  validateMessages={formValidationMessages}
  className='grid grid-cols-3 gap-2'
>
  <Form.Item
    name='itemname'
    label='Item Name '
   
  >
    <Input placeholder='Enter Item Name '/>
  </Form.Item>
            <Form.Item
    name='price'
    label='Price'
  >
    <Input placeholder='Enter Price'/>
  </Form.Item>
  <Form.Item name='status' label='Status'>
        <Select
     
          placeholder='Select Status'
          options={[
            { label: 'Active', value: 'Y' },
            { label: 'In Active', value: 'N' },
          ]}
          
        />
      </Form.Item>
  
  
</Form>
  </div>
)
}

export default UpdateInventoryCode