import { useState } from 'react'
import axios from 'axios'
import { BASE_URL } from '../utils/constants'

const useFetchPDF = () => {
	const [data, setData] = useState(null)
	const getData = async url => {
		const res = await axios.get(url)
		setData('data:application/pdf;base64,' + res?.data?.toString('base64'))
	}
	return [data, getData]
}

export default useFetchPDF
