import { Form, Input, Select } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'

const AddInvoiceNature = ({createinvoicenature}) => {
    const onFinish = (values) =>{
        createinvoicenature({
          ...values,
          message: 'B',
        })
      }
    return (
      <div>
      <Form
      layout='vertical'
      onFinish={onFinish}
      id='addnatureForm'
      validateMessages={formValidationMessages}
      className='grid grid-cols-2 gap-2'
    >
      <Form.Item
        name='natname'
        label='Nature Name'
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder='Enter Nature Name'/>
      </Form.Item>
      <Form.Item name='status' label='Status'>
          <Select
            placeholder='Select Status'
            options={[
              { label: 'Active', value: 'Y' },
              { label: 'In Active', value: 'N' },
            ]}
            
          />
        </Form.Item>
     
    </Form>
      </div>
    )
  }
  

export default AddInvoiceNature