import { EditFilled, PlusOutlined } from '@ant-design/icons'
import { Button,  Modal, Table,  Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import AddDemand from './AddDemand';
import AddDemandDetail from './AddDemandDetail';
import UpdateDemand from './UpdateDemand';
import moment from "moment";
import {  useGetdemanddetailMutation, useGetdemandlistQuery, useSavedemandMutation, useSavedemanddetailMutation } from '../inventoryService';
const CreateDemandRequisition=() =>{
  const {data:getdemadData, isLoading,refetch } = useGetdemandlistQuery(); 
  const[demandlist,setDemendlist]=useState([])
  const [savedemand, { isSuccess: addSuccess, isLoading: addLoading }] = useSavedemandMutation();
  const [getdemanddetail, { data: demanddetail, isLoading: subLoading }] = useGetdemanddetailMutation();
  const [savedemanddetail, {data:demanddata, isSuccess: saveSuccess, isLoading: saveLoading }] = useSavedemanddetailMutation();
  const [demanddetailinfo, setdemanddetailinfo] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDetailModal, setDetailModal] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showUpdatedetailModal, setShowUpdatedetailModal] = useState(false);
  

  useEffect(()=>{
    if(getdemadData){
      setDemendlist(getdemadData?.data)
    }
  },[getdemadData]) 
  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
    handlerefetch()
  }, [addSuccess]); // eslint-disable-line

  useEffect(() => {
    if (saveSuccess) setShowAddModal(false);
    handlerefetch()
  }, [saveSuccess]); // eslint-disable-line


  useEffect(()=>{
    if(demanddata)
  
    {
      console.log("demanddata",demanddata)
      getdemanddetail({
        demandid: demanddata?.data[0]?.demandid,
        message: "B",
      });
    }
  },[demanddata])//eslint-disable-line


  
  const tableColumns = [
    {
      title: "Demand ID",
      dataIndex: "demandid",
    },
    {
      title: "Description",
      dataIndex: "unitdescr",
    },
    {
      title: "Transaction Unit",
      dataIndex: "unit",
    },

    {
      title: "Request Unit",
      dataIndex: "requnit",
    },
    {
      title: "Demand Date",
      dataIndex: "demanddate",
      render:(data)=>(
        <div>
      {  moment(data?.demanddate)?.format("DD-MM-YYYY") }</div>
      )  
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
    },
  
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action(s)",
      render: (data) => (
        <div className='flex justify-center gap-2'>
     
        <Tooltip title='Add Demand Details '>
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => setDetailModal(true)}
          />
        </Tooltip>
        <Tooltip title="Update Demand">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdateModal(true);
            }}
          />
        </Tooltip>
        </div>
      ),
    },
  ];
  const ColumnsInfo = [
    {
      title: "Seq No.",
      dataIndex: "seqnum",
    },

    {
      title: "Item Description",
      dataIndex: "pridescr",
    },
    {
      title: "Nomncltre",
      dataIndex: "nomncltre",
    },

    {
      title: "UOM",
      dataIndex: "uom",
    },
    {
      title: "On Hand",
      dataIndex: "onhand",
    },
    {
      title: "LPR",
      dataIndex: "lpr",
    },
    {
      title: "Approved Qty",
      dataIndex: "approvedqty",
    },
    {
      title: "Demand Qty ",
      dataIndex: "demandqty",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
    },
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Update Detail Demand">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdatedetailModal(true);
            }}
          />
        </Tooltip>
      ),
    },
    
  ];
  const handlerefetch=()=>{
    refetch()
  }
  
  return (
   <section>
    <div>
        <h1 className=' col-span-2 flex justify-center items-center font-semibold h-12 text-2xl mt-0'>
          Create Demand Requisition
        </h1>
      </div>
      <div className='flex items-center justify-end px-5 py-3 pt-0 mt-0'>
        <Tooltip title='Add Demand'>
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className='p-2'>
        <Table
 
          columns={tableColumns}
          dataSource={demandlist}
          pagination={{ defaultPageSize: 5 }}
          loading={isLoading}
          bordered
          size='small'
          scroll={{ x: "fit-content" }}
          rowKey='type'
          onRow={(record) => {
            return {
              onClick: () => {
                setdemanddetailinfo(record);

                getdemanddetail({
                  
                  demandid: record?.demandid,
               
                  message: "B",
                });
              },
            };
          }}
          rowClassName={(record) =>
            record === demanddetailinfo
              ? "selected-row cursor-pointer"
              : "cursor-pointer"
          }
          rowStyle={(record) =>
            record === demanddetailinfo
              ? { backgroundColor: "gray", color: "white" }
              : {}
          }
          
        />
      </div>

      <Modal
        title='Add Demands'
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key='1'
            htmlType='submit'
            form='addDemandForm'
            loading={addLoading}
            type='primary'
          >
            Add
          </Button>,
          <Button key='2' onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddDemand savedemand={savedemand} handlerefetch={handlerefetch}  />
      </Modal>

      <Modal      
    title="Update Demand"
      open={showUpdateModal}
      onCancel={() => setShowUpdateModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="updateMDemandForm"
          // loading={updateLoading}
          type="primary"
        >
          Update
        </Button>,
        <Button key="2" onClick={() => setShowUpdateModal(false)}>
          Cancel
        </Button>,
      ]}>
      <UpdateDemand
           rowData={rowData} />
    </Modal>
      
      <div className='p-2' >
        <Table
          dataSource={demanddetail?.data}
          columns={ColumnsInfo}
          // onFinish={onFinish}
          pagination={{ defaultPageSize: 5 }}
          loading={subLoading}
          bordered
          size='small'
          scroll={{ x: "fit-content" }}
          rowKey='type'
        />
      </div>

      <Modal
        title='Add Demand Details '
        open={showDetailModal}
        onCancel={() => setDetailModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key='1'
            htmlType='submit'
            form='addDemandForm'
            loading={saveLoading}
            type='primary'
          >
            Add
          </Button>,
          <Button key='3' onClick={() => setDetailModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddDemandDetail   savedemanddetail={savedemanddetail}  demanddetailinfo={demanddetailinfo}
        onCancel={() => setDetailModal(false)} />
      </Modal>

      <Modal      
    title="Update Demand"
      open={showUpdatedetailModal}
      onCancel={() => setShowUpdatedetailModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="updateDemandForm"
          // loading={updateLoading}
          type="primary"
        >
          Update
        </Button>,
        <Button key="2" onClick={() => setShowUpdatedetailModal(false)}>
          Cancel
        </Button>,
      ]}>
      <UpdateDemand
           rowData={rowData} />
    </Modal>

      <div className='col-span-1 flex mt-2 justify-end gap-2'>
        <Button type='primary'
        //  onClick={()=>{onFinish()}}
         >
        Print
        </Button>
        <Button type='primary'
        //  onClick={()=>{onFinish()}}
         >
        Submit Demand
        </Button>

      </div>
   </section>
  )
}

export default CreateDemandRequisition