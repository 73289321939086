import { Button, Table } from 'antd';
import React, { useState } from 'react'
import { useGetreqmasterQuery, useGetrequestdetaildtoMutation, useItemreceiveMutation } from '../inventoryService';
import { useSelector } from 'react-redux';

const IndentReceiving = () => {
    const co = useSelector((state) => state.auth.user.co);
    const userid = useSelector((state) => state.auth.user.empno);
    const { data: getreqdata } = useGetreqmasterQuery();
    const [reqdetail, setreqdatail] = useState([]);
    const [getreqdetail, { data: getreqDetailData, isLoading: poLoading }] = useGetrequestdetaildtoMutation();
    const [itemreceive, { data: saveitemreceive }] = useItemreceiveMutation();
  //     const filter = useTableFilters
  
      const tableColumns = [
          {
            title: "Request Number",
            dataIndex: "reqnum",
            // ...filter("reqnum"),
            // sorter: (a, b) => a?.reqnum - b?.reqnum,
            // defaultSortOrder: "descend",
          },
          {
            title: "Request Type",
            dataIndex: "ir",
            // ...filter("reqtype"),
            // sorter: (a, b) => a?.reqtype - b?.reqtype,
            // defaultSortOrder: "descend",
          },
          {
            title: "Request Date",
            dataIndex: "reqdate",
            // ...filter("reqdate"),
            // sorter: (a, b) => a?.reqdate?.localeCompare(b?.reqdate),
          },
  
          {
            title: "Status",
            dataIndex: "status",
          //    ...filter("status"),
          //   sorter: (a, b) => a?.status?.localeCompare(b?.sttime),
          },
          
  
        ];
  
        const data = [
          {
            title: "Primary Description",
            dataIndex: "itemname",
            
          },
          
          {
            title: "Type",
            dataIndex: "type",
            
          },
          {
            title: "Strength",
            dataIndex: "strength",
            
          },
          {
            title: "Qty On Hand",
            dataIndex: "onhand",
            
          },
          {
            title:"Packet Size",
            dataIndex:"packsize"
          },
          {
            title: "Qty Demand",
            dataIndex: "qtydemand",
            
          },
          {
            title: "Qty Approved",
            dataIndex: "qtyaprvd",
            
          },
         
          {
            title: "UOM",
            dataIndex: "uom",
            
          },
  
        ];
        const onFinish = (values) => {
            itemreceive({
              pco: co,
              puserid: userid,
              preqdate:reqdetail?.reqdate,
              preqnum:reqdetail?.reqnum,
              punit:reqdetail?.unit
              
            });
        
            
          };
  
      
  
    return (
     
    <section>
  <div>
  <h1 className=" col-span-2 flex justify-center items-center font-semibold h-12 text-2xl mt-0">
          Indent Receiving 
          </h1>
  </div>
  <div>
        <Table
          dataSource={getreqdata?.data}
          columns={tableColumns}
          pagination={{ defaultPageSize: 5 }}
          // loading={isLoading}
          bordered
          size="small"
          // scroll={{ x: 'fit-content' }}
          rowKey="type"
          onRow={(record) => {
              return {
                onClick: () => {
                  setreqdatail(record);
  
                  getreqdetail({
                    
                    reqdate: record?.reqdate,
                    reqnum: record?.reqnum,
            
                    message: "B",
                  });
                },
              };
            }}
            rowClassName={(record) =>
              record === reqdetail
                ? "selected-row cursor-pointer"
                : "cursor-pointer"
            }
            rowStyle={(record) =>
              record === reqdetail
                ? { backgroundColor: "gray", color: "white" }
                : {}
            } 
        />
  </div> 
  
  
  <div>
        <Table
          dataSource={getreqDetailData?.data}
          columns={data}
          pagination={{ defaultPageSize: 5 }}
          // loading={isLoading}
          bordered
          size="small"
          // scroll={{ x: 'fit-content' }}
          rowKey="type"
        />
  </div> 
  <div className="col-span-1 flex items-center mt-8 justify-end">
                
                    <Button className="mr-3" type="primary" onClick={()=>{onFinish()}}>
                      Verify/Received
                    </Button>                
                    <Button type="primary" htmlType="submit">
                      Print
                    </Button>
                 
                </div>
      
  </section>
  
  
  
  
    )
}

export default IndentReceiving