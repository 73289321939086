import { Form,Select,Table, Tag } from 'antd'
import React, { useState } from 'react'
import { useGetmateriallocMutation, useGetonhanddetailMutation, useGetunitlovQuery } from '../inventoryService';
import { useSelector } from 'react-redux';
import useTableFilters from '../../../hooks/useTableFilter';

const MaterialsInquiry=()=> {
  const co = useSelector((state) => state.auth.user.co);
  const { data: getunitlov } = useGetunitlovQuery(co);
  const [onhand, setonhand] = useState(null);
  const [getmaterial, { data: getMateriallov, isLoading: gmLoading }] = useGetmateriallocMutation();
  const [getonhanddetail, { data: onhanddetail, isLoading:ghLoading }] = useGetonhanddetailMutation();
  const filter = useTableFilters();
  const tableColumns = [
    {
      title: "Primary Description",
      dataIndex: "pridescr",
       ...filter("pridescr"),
        sorter: (a, b) => a?.pridescr - b?.pridescr,
        defaultSortOrder: "descend",
      
    },
    {
      title: "Type",
      dataIndex: "type",
      ...filter("type"),
        sorter: (a, b) => a?.type - b?.type,
        defaultSortOrder: "descend",  
    },
    {
      title: "Strength",
      dataIndex: "strength",
      
    },
    {
      title: "On Hand",
      dataIndex: "onhand",
      
    },
    {
      title: "Material Code",
      dataIndex: "nomncltre",
      
    },
    {
      title: "Previous Material Code",
      dataIndex: "prenomn",
      
    },
    {
      title: "Create Date",
      dataIndex: "credate",
      
    },

    {
      title: "Allocate",
      dataIndex: "allocate",
      
    },
    {
      title: "ATP",
      dataIndex: "atp",
      
    },
    {
      title: "Min Stock",
      dataIndex: "minstock",
      
    },
    {
      title: "Max Stock",
      dataIndex: "maxstock",
      
    },
    {
      title: "Stafey Stk",
      dataIndex: "safteystock",
      
    },
    {
      title: "Re-Order lvl",
      dataIndex: "reorderlevel",
      
    },
    {
      title: "Reserved",
      dataIndex: "reserved",
      
    },
    {
      title: "In QC",
      dataIndex: "inqc",
      
    },
    {
      title: "In-Transit",
      dataIndex: "intransit",
      
    },
    {
      title: "On PO",
      dataIndex: "onpo",
      
    },
    {
      title: "Scrap",
      dataIndex: "scrap",
      
    },
    

    {
      title: "Status",
      render: (_, { status }) =>
        status === "Y" ? (
          <Tag color="success">Active</Tag>
        ) : (
          <Tag color="error">In Active</Tag>
        ),
      // sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },

  ];
  const Columns = [
    {
      title: "Reference",
      dataIndex: "reference",
      
    },
    {
      title: "Trans Type",
      dataIndex: "transtype",
 
    },
   
    {
      title: "Date",
      dataIndex: "transdate",
 
    },
    {
      title: "Action",
      dataIndex: "onhand",
 
    },
   
    {
      title: "Quantity",
      dataIndex: "quantity",
 
    },
    {
      title: "Balance",
      dataIndex: "balance",
 
    },
    
  ];
  return (
   <section>
    <div>
<h1 className=" col-span-2 flex justify-center items-center font-semibold h-12 text-2xl mt-0">
        Unit Wise Materials Inquiry 
        </h1>
</div>

    <Form
    layout='vertical'>
<div className='grid sm:grid-cols-3 grid-cols-1 gap-2 p-2'>
<Form.Item name='warehouse' label='Warehouse'>
      <Select
          placeholder='Select Warehouse'
          allowClear
          showSearch
          filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
       
          onChange={(value)=>{
            getmaterial({
              co:co,
                  unit: value,
                  message: "B",
                });
          }}

          
          options={getunitlov?.data?.map((item) => ({
            value: item?.orgunitid,
            label: item?.unitname,
          }))}
        />
      </Form.Item>
    

</div>
    </Form>

<div className='p-2'>
      <Table virtual scroll={{ x: 2200 }}
    
        dataSource={getMateriallov?.data}
        columns={tableColumns}
        pagination={{ defaultPageSize: 5 }}
        loading={gmLoading}
        bordered
        size="small"
        // scroll={{ x: 'fit-content' }}
        rowKey="type"
        onRow={(record) => {
            return {
              onClick: () => {
                setonhand(record);

                getonhanddetail({
                  unit: record?.unit,
                  nomncltre: record?.nomncltre,

                  message: "B",
                });
              },
            };
          }}
          rowClassName={(record) =>
            record === onhand
              ? "selected-row cursor-pointer"
              : "cursor-pointer"
          }
          rowStyle={(record) =>
            record === onhand
              ? { backgroundColor: "gray", color: "white" }
              : {}
          } 
      />
</div> 
{onhand &&
 <div className="p-2">
      <Table
        dataSource={onhanddetail?.data}
        columns={Columns}
        pagination={{ defaultPageSize: 5 }}
        loading={ghLoading}
        bordered
        size="small"
        rowKey="type"
      />
    </div>
}


   </section>
  )
}

export default MaterialsInquiry