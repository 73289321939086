import { Form, Input, Select } from 'antd';
import React, { } from 'react'
import formValidationMessages from '../../../utils/formValidationMessages';
import { useSelector } from 'react-redux';
import { useGetNomncltreLovQuery } from '../inventoryService';

const AddDemandDetail=({savedemanddetail,demanddetailinfo}) =>{
  const co = useSelector((state) => state.auth.user.co);
  const { data: getnomncltre } = useGetNomncltreLovQuery({unit:demanddetailinfo?.unit});
  console.log("getnomncltre",getnomncltre)
  const userid = useSelector((state) => state.auth.user.empno);
    const [form] = Form.useForm();
    const onFinish = (values) =>{

      const selectedRecord = getnomncltre?.data.find(
        (item) => item.nomncltre === values?.nomncltre
      );
      
      savedemanddetail({
        co:co,
        userid:userid,
        demandid:demanddetailinfo?.demandid,
        unit:demanddetailinfo?.unit,
        minparlevel:selectedRecord?.minprlvl,
        maxparlevel:selectedRecord?.maxprlvl,

          ...values,
          message: 'B',
        })
      
    
      }
      const handleSelectChange = (value) => {
        const selectedRecord = getnomncltre?.data.find(
          (item) => item.nomncltre === value
        );
        form.setFieldsValue({
          nomncltre:selectedRecord?.nomncltre,
          uom:selectedRecord?.uom,
          unitprice:selectedRecord?.unitprice,
          onhand:selectedRecord?.onhand,
          minprlvl:selectedRecord?.minprlvl,
          maxprlvl:selectedRecord?.maxprlvl


        })
        console.log("selectedRecord",selectedRecord)
      }
  return (
    <Form
    form={form}
    layout='vertical'
    onFinish={onFinish}
    autoComplete='off'
    id='addDemandForm'
    validateMessages={formValidationMessages}
  >
    <div className='grid sm:grid-cols-5 grid-cols-1 gap-2'>

      <Form.Item name='descr' label='Item Description'>
        <Select
          placeholder='Select Item Description'
          // allowClear
          // showSearch
          // filterOption={(input, option) =>
          //     option?.label?.toLowerCase()?.includes(input?.toLowerCase())
          //   }
          onChange={(value)=>
          {
            handleSelectChange(value)
              // setShowdata(value);
          }}
          options={getnomncltre?.data?.map((item) => ({
           value:item?.nomncltre,
           label:item?.pridescr
          
          }))}
        />
      </Form.Item>

      <Form.Item name='nomncltre' label='Nomncltre'>
      <Input placeholder='Enter Nomncltre' />
      </Form.Item>
      <Form.Item name='uom' label='UOM'>
        <Input placeholder='Enter UOM' />
      </Form.Item>

      <Form.Item name='unitprice' label='Unit Price'>
        <Input placeholder='Enter Unit Price' />
      </Form.Item>
      <Form.Item name='onhand' label='On Hand'>
        <Input placeholder='Enter On Hand' />
      </Form.Item>
      <Form.Item name='lpr' label='LPR'>
        <Input placeholder='Enter LPR' />
      </Form.Item>
      <Form.Item name='demandqty' label='Demand Qty'>
        <Input placeholder='Enter Demand Qty' />
      </Form.Item>
      <Form.Item    name='strength' label='Strength'>
        <Input placeholder='Enter Strength' />
      </Form.Item>
      <Form.Item name='minprlvl' label='Min PAR Level'>
        <Input placeholder='Enter Min PAR Level' />
      </Form.Item>
      <Form.Item name='maxprlvl' label='Max PAR level'>
        <Input placeholder='Enter Max PAR level' />
      </Form.Item>
      <Form.Item className='w-96'   name='remarks' label='Remarks'>
        <Input placeholder='Enter Remarks' />
      </Form.Item>
    </div>
    
  </Form>

);
};

export default AddDemandDetail