import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { api } from './features/api/api'
import { logoutUser, restoreSession } from './features/auth/authSlice'
import AppRouter from './routes/AppRouter'
import { SESSION_STORAGE_PREFIX } from './utils/constants'
import Spinner from './utils/Spinner'

const App = () => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (
      sessionStorage.getItem(`${SESSION_STORAGE_PREFIX} clientSecret`) &&
      sessionStorage.getItem(`${SESSION_STORAGE_PREFIX} clientMenu`)
    )
      dispatch(restoreSession())
    setLoading(false)
  }, [dispatch])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      document.addEventListener('contextmenu', e => e.preventDefault())
      function ctrlShiftKey(e, keyCode) {
        return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0)
      }
      function ctrlKey(e, keyCode) {
        return e.ctrlKey && e.keyCode === keyCode.charCodeAt(0)
      }
      document.onkeydown = e => {
        if (e.key === 123 || ctrlShiftKey(e, 'I') || ctrlShiftKey(e, 'J') || ctrlShiftKey(e, 'C') || ctrlKey(e, 'U'))
          return false
      }
    }
    return () => {
      if (process.env.NODE_ENV === 'production') document.removeEventListener('contextmenu')
    }
  }, [])

  useEffect(() => {
    if (isLoggedIn && process.env.NODE_ENV === 'production') {
      const handleInactive = () => {
        dispatch(logoutUser())
        dispatch(api.util.resetApiState())
      }
      let timeoutId
      const resetTimeout = () => {
        if (timeoutId) {
          clearTimeout(timeoutId)
        }
        timeoutId = setTimeout(handleInactive, 3 * 60 * 1000)
      }
      resetTimeout()
      const eventListeners = ['mousemove', 'mousedown', 'keypress']
      eventListeners.forEach(event => {
        document.addEventListener(event, resetTimeout)
      })
      return () => {
        eventListeners.forEach(event => {
          document.removeEventListener(event, resetTimeout)
        })
        if (timeoutId) {
          clearTimeout(timeoutId)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  if (loading) {
    return (
      <div className='grid place-content-center h-full'>
        <Spinner fontSize={64} />
      </div>
    )
  }

  return <AppRouter />
}

export default App
