export const SESSION_STORAGE_PREFIX = "Awanstore";
// export const BASE_URL = 'https://medix.fauji.org.pk/';
// export const BASE_URL = 'http://10.1.0.142/';
//  export const BASE_URL = 'http://172.16.12.48:8091/'
// export const BASE_URL = 'http://localhost:8080/' // dew server
// export const BASE_URL = 'http://172.16.70.90:8082/';   ///Registration
// export const BASE_URL = 'http://172.16.70.90:8090/'       ///Appointment
// export const BASE_URL = 'http://172.16.70.90:8090/'
// export const BASE_URL = 'http://198.100.101.2:8080/'
//  export const BASE_URL = 'http://157.173.127.151:8080/'
export const BASE_URL = "http://aawanstore.com/";
