import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import formValidationMessages from "../../../utils/formValidationMessages";
import { useGetPoNomnListQuery, useGetponomnlistdetailMutation, useSavePoDetailMutation } from "../inventoryService";


function AddPoDetails({ poDetail , onCancel}) {
  console.log("poDetail", poDetail);
  // const [savevendor, { data: lovgetponomnlist }] =
  //   useLovgetponomnlistMutation();
  const [form] = Form.useForm();
  const { data: lovDescription } = useGetPoNomnListQuery({
    unit: poDetail?.unit,
    vendorid: poDetail?.vendorid,
  });
  // const co = useSelector((state) => state.auth.user.co);
  const [ saveponomnlistdetail, { data: getponomnlistdetail }] = useGetponomnlistdetailMutation();
  const[nomncltrelistdetail,setNomncltrelistdetail]=useState(null)
  const[ saveDetails,{data: getDetails}]=useSavePoDetailMutation();

  useEffect(() => {
    if (getponomnlistdetail) {
      setNomncltrelistdetail(
        getponomnlistdetail?.data[0]
      )
      
    }
  }, [getponomnlistdetail]); //eslint-disable-line 
  useEffect(() =>
    {
      if (nomncltrelistdetail){
        form.setFieldValue({
          
          packsize:nomncltrelistdetail?.packsize
        })
      }
    
  },[nomncltrelistdetail,form])

  const onFinish=(values)=>{
    if(values){
    saveDetails({
      description:values?.description,
      packsize:values?.packsize,
      packqty:values?.unitqty,
      rate:values?.packrate,
      rateunit:values?.unitrate,
      discpct:values?.disc,
      taxpct:values?.tax,
      totalamount:values?.totalamount,
      uom:values?.uom,
      nomncltre:nomncltrelistdetail?.nomncltre,
      podate:poDetail?.podate,
      ponum:poDetail?.ponum,
      co:poDetail?.co,
      userid:poDetail?.userid,
      poseq:poDetail?.poseq,
      qtyrcvd:values?.qtyrcvd,
      qtyaprvd:values?.qtyaprvd,

    });
    if (saveDetails){
      onCancel()
    }}
  };

  
  return (
    <Form
      form={form}
      layout='vertical'
       onFinish={onFinish}
    
      autoComplete='off'
      id='addPODetailform'
      validateMessages={formValidationMessages}
    >
      <div className='grid sm:grid-cols-5 grid-cols-1 gap-2'>
        <Form.Item name='description' label='Description'
         rules={[
                  {
                    required: true,
                  },
                ]}>
          <Select
            placeholder='Select Description'
            onChange={(value)=> {saveponomnlistdetail({
              // co:co,
              unit: poDetail?.unit,
              vendorid: poDetail?.vendorid,
              nomncltre:value,

            })}}
            options={lovDescription?.data?.map((item) => ({
              value: item?.nomncltre,
              label: item?.pridescr,

            }))}
          />
        </Form.Item>

        <Form.Item name='packsize' label='Pack Size'>
          <Input />
        </Form.Item>

        <Form.Item name='qtypack' label='QTY/Pack'>
          <Input />
        </Form.Item>
        <Form.Item name='qtyrcvd' label='Quatity Received'>
          <Input />
        </Form.Item>
        <Form.Item name='qtyaprvd' label='Quatity Approved'>
          <Input />
        </Form.Item>
        <Form.Item name='unitqty' label='Unit QTY'>
          <Input />
        </Form.Item>
        <Form.Item name='packrate' label='Pack Rate'>
          <Input />
        </Form.Item>
        <Form.Item name='unitrate' label='Unit Rate'>
          <Input />
        </Form.Item>
        <Form.Item name='discpct' label='Disc %'>
          <Input />
        </Form.Item>
        <Form.Item name='taxpct' label='Tax %'>
          <Input />
        </Form.Item>
        <Form.Item name='totalamount' label='Total Amount'>
          <Input />
        </Form.Item>
        <Form.Item name='uom' label='UOM'>
          <Input />
        </Form.Item>
      </div>
    </Form>
  );
}

export default AddPoDetails;
