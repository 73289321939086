import InvPurchaseDetail from "../features/inventorypurchase/pages/InvPurchaseDetail";
import InvStock from "../features/inventorypurchase/pages/InvStock";
import PurchaseOrder from "../features/inventorypurchase/pages/PurchaseOrder";

export const inventoryPurchaseRoutes = [
  {
    path: "PurchaseOrder",
    element: <PurchaseOrder />,
  },

  {
    path: "InventoryStock",
    element: <InvStock />,
  },

  {
    path: "PODetail",
    element: <InvPurchaseDetail />,
  },
];
