import React, { useEffect, useState } from 'react'
import { useCreatePurchaseOrderMutation, useGetInvPurchaseOrderQuery, useUpdatePurchaseOrderMutation } from '../inventorypurchaseService';
import useTableFilters from '../../../hooks/useTableFilter';
import { Button, Modal, Table, Tag, Tooltip } from 'antd';
import { EditFilled, PlusOutlined } from '@ant-design/icons';
import AddPurchaseOrder from '../components/AddPurchaseOrder';

import moment from "moment";
import UpdatePurchaseOrder from '../components/UpdatePurchaseOrder';

const PurchaseOrder = () => {
  const { data, isLoading,refetch} = useGetInvPurchaseOrderQuery();
  const [createpo, { isSuccess: addSuccess, isLoading: addLoading }] = useCreatePurchaseOrderMutation();
  const [ updatepo, {data:updatepoData, isSuccess: updateSuccess, isLoading: updateLoading }, ] = useUpdatePurchaseOrderMutation();
  const [showAddModal, setShowAddModal] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const filter = useTableFilters();
  const[tableDatainfo,setTableDatainfo]=useState([]);
  useEffect(()=>{
      if(data){
        setTableDatainfo(data?.data)
      }
    },[data])
    useEffect(() => {
      if (addSuccess) setShowAddModal(false);
       handlerefetch()
    }, [addSuccess]); // eslint-disable-line

    useEffect(() => {
      if (updateSuccess) setShowUpdateModal(false);
      handlerefetch();
    }, [updateSuccess]); // eslint-disable-line
  const tableColumns = [
    {
      title:"Vendor Name",
      dataIndex: "vendorname",
      ...filter("vendorname"),
   
    }, 
    {
      title:"Purchase No.",
      dataIndex: "purchasenum",
      ...filter("purchasenum"),
   
    },
    {
      title:"Description",
      dataIndex: "descr",
      ...filter("descr"),
   
    },
    {
      title:"Purchase Date ",
      dataIndex: "purchasedate ",
      render:(data)=>(
        <div>
      {  moment(data?.purchasedate)?.format("YYYY-MM-DD") }</div>
      )
     
   
    },
    {
      title:"Quotation No.",
      dataIndex: "quotnum",
      ...filter("quotnum"),
   
    },
    {
      title:"Quotation Date",
      dataIndex: "quotdate ",
      render:(data)=>(
        <div>
      {  moment(data?.quotdate)?.format("YYYY-MM-DD") }</div>
      )
   
    },
    

    {
      title: "Status",
      render: (_, { status }) =>
          status === "Y" ? (
          <Tag  color="green">Active</Tag>
        ) : (
          <Tag color="red">In Active</Tag>
        ),
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
        {
          title: "Action(s)",
          render: (data) => (
            <Tooltip title="Update PO">
              <Button
                type="primary"
                className="btn-warning-primary"
                icon={<EditFilled />}
                onClick={() => {
                  setRowData(data);
                  setShowUpdateModal(true);
                }}
              />
            </Tooltip>
          ),
        },
     
     
    ];
    const handlerefetch=()=>{
      refetch()
    }
  return (
    <section>
    <div className="flex items-center justify-between px-5 py-3 ">
      <h1 className="font-semibold text-base sm:text-lg">Purchase Order</h1>
      <Tooltip  title="Add New PO">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setShowAddModal(true)}
        />
      </Tooltip>
    </div>
    <div className='p-2'>
      <Table

       columns={tableColumns}
       dataSource={tableDatainfo}
        pagination={{ defaultPageSize: 5 }}
        loading={isLoading}
        bordered
        size='small'
        scroll={{ x: "fit-content" }}
        rowKey='type'
 
        
      />
    </div>
    <Modal
    title="Add PO"
    open={showAddModal}
    onCancel={() => setShowAddModal(false)}
    centered
    width={1000}
    destroyOnClose
    footer={[
      <Button
        key="1"
        htmlType="submit"
        form="addPOForm"
        loading={addLoading}
        type="primary"
      >
        Add
      </Button>,
    
    ]}
  >
<AddPurchaseOrder
  createpo={createpo} 
  handlerefetch={handlerefetch}
  />
  </Modal>

  <Modal
      title="Update PO"
      open={showUpdateModal}
      onCancel={() => setShowUpdateModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="updatePOForm"
        loading={updateLoading}
          type="primary"
        >
          Update
        </Button>,
        <Button key="2" onClick={() => setShowUpdateModal(false)}>
          Cancel
        </Button>,
      ]}
    >
      <UpdatePurchaseOrder
        rowData={rowData}
        updatepoData={updatepoData}
        updatepo={updatepo}
      />
    </Modal>

  </section>
  )
}

export default PurchaseOrder