import { Form, Input } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'

const AddInvStock = ({createinvstock}) => {
  const onFinish = (values) =>{
    createinvstock({
        ...values,
        message: 'B',
      })
    }
  return (
    <div>
    <Form
    layout='vertical'
    onFinish={onFinish}
    id='addInvStockForm'
    validateMessages={formValidationMessages}
    className='grid grid-cols-3 gap-2'
  >
    <Form.Item
      name='itemname'
      label='Item Name'
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter Item Name'/>
    </Form.Item>

    <Form.Item
      name='currentquantity'
      label='Current Quantity'
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter Current Quantity'/>
    </Form.Item>
   
    <Form.Item
      name='currentprice'
      label='Current Price'
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter Current Price'/>
    </Form.Item>
   

   
  </Form>
    </div>
  )
}


export default AddInvStock