import CreateDemandRequisition from "../features/Inventory/pages/CreateDemandRequisition";
import DemandGeneration from "../features/Inventory/pages/DemandGeneration";
import IndentReceiving from "../features/Inventory/pages/IndentReceiving";
import ItemsReceive from "../features/Inventory/pages/ItemsReceive";
import MaterialsInquiry from "../features/Inventory/pages/MaterialsInquiry";
import PurchaseOrder from "../features/Inventory/pages/PurchaseOrder";
import SearchRequest from "../features/Inventory/pages/SearchRequest";


export const inventoryRoutes = [
  

  {
    path: 'MaterialsInquiry',
    element: <MaterialsInquiry/>
  },

  {
    path: 'PurchaseOrder',
    element: <PurchaseOrder/>
  },

  {
    path: 'ItemsReceive',
    element: <ItemsReceive/>
  },
  {
    path: 'IndentReceiving',
    element: <IndentReceiving/>
  },


  {
    path: 'SearchRequest',
    element: <SearchRequest/>
  },

  {
    path: 'CreateDemandRequisition',
    element: <CreateDemandRequisition/>
  },
  {
    path: 'DemandGeneration',
    element: <DemandGeneration/>
  },
  

]
