import { Button, Card, Form, Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
import { FiMinus } from "react-icons/fi";
import productsimg from "../../../assets/images/defaultimg.png";
const OrderPlace = ({ rowData, saveuserproduct }) => {
  let dataTable = [rowData];
  const [form] = Form.useForm();
  const [tabledata, setTabledata] = useState([]);
  useEffect(() => {
    if (rowData) {
      const updatedTableData = dataTable.map((item) => ({
        mainco: item.mainco,
        pcategory: item.pcategory,
        pdescr: item.pdescr,
        pid: item.pid,
        pname: item.pname,
        pnewprice: item.pnewprice,
        pprice: item.pprice,
        ppic: item.ppic,
        status: item.status,
        quantity: 1,
      }));
      setTabledata(updatedTableData);
    }
  }, [rowData]); //eslint-disable-line
  const incrementCount = (pid) => {
    setTabledata((prevData) =>
      prevData.map((item) =>
        item.pid === pid ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const decrementCount = (pid) => {
    setTabledata((prevData) =>
      prevData.map((item) =>
        item.pid === pid && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };
  const columns = [
    {
      title: "Item",
      render: (data) => {
        return (
          <Card className="grid justify-center items-start gap-1">
            <div className="pb-1">
              <img
                src={data?.ppic ? data?.ppic : productsimg}
                alt="product"
                className="w-[12rem] h-[12rem] object-cover rounded-md"
              />
            </div>
            <div className="flex justify-between items-center gap-2 mb-2">
              <div className="pr-2">
                <div className=" text-left font-bold">{data?.pname}</div>
              </div>
              <div className=" flex-row justify-end  gap-1">
                <div className=" text-left font-bold">
                  <h1 className="text-[#33a69b]">PKR&nbsp;{data?.pnewprice}</h1>
                </div>
              </div>
            </div>
            <div className=" flex justify-center items-start">
              <Button
                onClick={() => decrementCount(data.pid)} // Pass product key
                size="small"
                className=" btncustomize"
              >
                <FiMinus className=" text-lg" />
              </Button>
              <h1>&nbsp;&nbsp;{data.quantity || 1}&nbsp;&nbsp;</h1>

              <Button
                onClick={() => incrementCount(data.pid)} // Pass product key
                size="small"
                className="btncustomize"
              >
                <GoPlus className=" text-lg" />
              </Button>
            </div>
          </Card>
        );
      },
    },
  ];
  const onFinish = (values) => {
    console.log("values", values);
    console.log("tab", tabledata?.length);
    const sv1 = tabledata?.map((item) => ({
      odprdname: item?.pname,
      omtsngleprodprice: item?.pnewprice,
      odsamedetailqty: item.quantity,
    }));
    const sv2 = tabledata?.map((item) => ({
      totalprice: item.pnewprice * item.quantity || item.pnewprice,
    }));
    saveuserproduct({
      omcount: tabledata?.length || 1,
      omtotprice: sv2[0]?.totalprice,
      customername: values?.customername,
      cellno: values?.cellno,
      address: values?.customername,
      dilvraddress: values?.deliveryaddress,
      custemail: values?.customeremail,
      sv1: sv1,
    });
  };
  return (
    <div>
      <Table
        columns={columns}
        dataSource={tabledata}
        pagination={false}
        rowKey="pid"
        scroll={{ x: "fit-content" }}
        className="hideheader"
      />
      <Form form={form} onFinish={onFinish} layout="vertical">
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-1">
          <Form.Item
            name="customername"
            label="Customer Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="cellno"
            label="Mobile Number"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="address" label="Address">
            <Input />
          </Form.Item>
          <Form.Item
            name="deliveryaddress"
            label="Delivery Address"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="customeremail"
            label="Customer Email"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label=" ">
            <Button className="btncustomize" htmlType="submit">
              Purchase
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default OrderPlace;
